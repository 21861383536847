import { styled } from '@mui/system';
import { TableCell } from '@mui/material';
import { useEffect, useRef } from 'react';

export const addOrderFilter = (loginUser, pageName, selectedLocationIndex) => {
    let qp = "";
    if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && loginUser.department != 4) {
        qp += (loginUser?.location?.code === 'NL' ? `userNLId=${loginUser.id}&` : (loginUser?.location?.code === 'IL' ? `userILId=${loginUser.id}&` : (loginUser?.location?.code === 'US' ? `userNLId=${loginUser.id}&` : "")));
    }
    if (pageName === 'orderHistory' || pageName === 'manifest') {
        if (qp != "") {
            qp += `&`;
        }
        qp += `showHistoryOrdersOnly=true&`
    } else {
        if (qp != "") {
            qp += `&`;
        }
        qp += `showHistoryOrdersOnly=false&`;
    }
    if (loginUser.authority != "Admin" && loginUser.department != 4) {
        if (loginUser.department) {
            qp += "userVisibility=" + loginUser.department + "&";
        } else
            qp += "userVisibility=1&";
    }
    if (loginUser.department == 4) {
        //qp += `userLocalSalesId=` + loginUser.id + "&";
    }

    if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) {
        qp += `selectedLocation=${loginUser?.location?.code}&showUnassignedOrders=true&`;
    } else if (selectedLocationIndex == 0) {
        qp += `selectedLocation=NL&showUnassignedOrders=true&`
    } else if (selectedLocationIndex == 1) {
        qp += `selectedLocation=US&showUnassignedOrders=true&`
    } else if (selectedLocationIndex == 2) {
        qp += `selectedLocation=ALL&showUnassignedOrders=true&`
    } else if (selectedLocationIndex == 3) {
        qp += `selectedLocation=IL&showUnassignedOrders=true&`
    }
    qp += `dep=${loginUser.department ? loginUser.department : 1}&`
    return qp;
}

export const addParcelFilter = (loginUser, pageName, selectedLocationIndex, selectedTabIndexName) => {
    let qp = "";
    if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User') {
        qp += (loginUser?.location?.code === 'NL' ? `userNLId=${loginUser.id}&` : (loginUser?.location?.code === 'IL' ? `userILId=${loginUser.id}&` : ""));
    }
    if (selectedTabIndexName === 'ParcelsHistory' || selectedTabIndexName === 'DropsHistory' || pageName === 'manifest') {
        if (qp != "") {
            qp += `&`;
        }
        qp += `showHistoryParcelsOnly=true&`
    }

    if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) {
        qp += ((loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US') ? `selectedLocation=${loginUser?.location?.code}&showUnassignedParcels=true&` : '');
    } else if (selectedLocationIndex == 0) {
        qp += `selectedLocation=NL&showUnassignedParcels=true&`
    } else {
        qp += `selectedLocation=US&showUnassignedParcels=true&`
    }
    if (selectedTabIndexName == 'Drops' || selectedTabIndexName == 'DropsHistory') {
        qp += `dropStatus=true&`
    } else {
        qp += `dropStatus=false&`
    }
    qp += `dep=1&`
    return qp;
}

export const addSearchFilter = (customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber = null, etaDate = null, etdDate = null) => {
    const params = [];
    if (fileNumber) {
        params.push(`fileNumber=${fileNumber}`);
    }
    if (customerId) {
        params.push(`customerId=${customerId}`);
    }
    if (urgentShipment != null) {
        params.push(`urgentShipment=${urgentShipment == 1 ? true : false}`);
    }
    //if (userLocalNumber) {
    if (search) {
        params.push(`jobNumber=${search}`);
    }

    if (userAbroadNumber) {
        params.push(`UserAbroadNumber=${userAbroadNumber}`);
    }

    if (consigneeId) {
        params.push(`consigneeId=${consigneeId}`);
    }

    if (shipperId) {
        params.push(`shipperId=${shipperId}`);
    }

    if (countryOfOriginId) {
        params.push(`countryOfOriginId=${countryOfOriginId}`);
    }

    if (originState) {
        params.push(`originState=${originState}`);
    }

    if (shippingModeId) {
        params.push(`shippingModeId=${shippingModeId}`);
    }

    if (subModeId) {
        params.push(`subModeId=${subModeId}`);
    }

    if (userLocalId) {
        params.push(`userLocalId=${userLocalId}`);
    }

    if (userTracer) {
        params.push(`userLocalTracerId=${userTracer}`);
    }

    if (userCustoms) {
        params.push(`userLocalCustomsId=${userCustoms}`);
    }

    if (userAbroadId) {
        params.push(`userAbroadIdOrEmpty=${userAbroadId}`);
    }

    if (dueDate) {
        params.push(`dueDate=${dueDate.split('.')[0].replace('T', ' ')}`);
    }

    if ((pageName === 'orderHistory' || pageName === 'ParcelsHistory' || pageName === 'DropsHistory') && lastUpdateDate) {
        params.push(`lastUpdateDate=${lastUpdateDate.split('.')[0].replace('T', ' ')}`);
    }

    if (reminderDate) {
        params.push(`reminderDate=${reminderDate.split('.')[0].replace('T', ' ')}`);
    }

    if (ilReminderDate) {
        params.push(`ilReminderDate=${ilReminderDate.split('.')[0].replace('T', ' ')}`);
    }

    //if (search) {
    //    params.push(`search=${search}`);
    //}

    if (startReminderDate) {
        params.push(`startReminderDate=${startReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (endReminderDate) {
        params.push(`endReminderDate=${endReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (startIlReminderDate) {
        params.push(`startIlReminderDate=${startIlReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (endIlReminderDate) {
        params.push(`endIlReminderDate=${endIlReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    var ex = true;
    if (etaDate) {
        var dt = new Date(etaDate);
        /*var month = dt.getMonth() + 1;
        var mon = dt.toLocaleString('default', { month: 'short' });;
        var date = dt.getDate();
        var year = dt.getFullYear();
        var txt1 = mon + " " + (date < 10 ? "0" + date : date) + " " + (year < 10 ? "0" + year : year);
        var txt = (year < 10 ? "0" + year : year) + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date);*/
        params.push(`etaDate=${dt.getTime()}`);
        //params.push(`etaDate1=${txt1}`);
        //params.push(`stageSearch=5`);
        //ex = false;
    }
    if (etdDate) {
        var dt = new Date(etdDate);
        /*var month = dt.getMonth() + 1;
        var mon = dt.toLocaleString('default', { month: 'short' });;
        var date = dt.getDate();
        var year = dt.getFullYear();
        var txt1 = mon + " " + (date < 10 ? "0" + date : date) + " " + (year < 10 ? "0" + year : year);
        var txt = (year < 10 ? "0" + year : year) + "-" + (month < 10 ? "0" + month : month) + "-" + (date < 10 ? "0" + date : date);*/
        params.push(`etdDate=${dt.getTime()}`);
        //params.push(`etdDate1=${txt1}`);
        //params.push(`stageSearch=5`);
        //ex = false;
    }
    if (stageSearch) {
        params.push(`stageSearch=${stageSearch}`);
    }
    if (stageStateSearch) {
        params.push(`stageStateSearch=${stageStateSearch}`);
    }
    if (reminderDateAdv) {
        params.push(`reminderDateAdv=${reminderDateAdv.split('.')[0].replace('T', ' ')}`);
    }

    if (ilReminderDateAdv) {
        params.push(`ilReminderDateAdv=${ilReminderDateAdv.split('.')[0].replace('T', ' ')}`);
    }

    if (params.length > 0) {
        return params.join('&') + '&';
    }
    return '';
}
export const addSearchFilterParcel = (userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName) => {
    const params = [];

    if (userLocalNumber) {
        params.push(`jobNumber=${userLocalNumber}`);
    }

    if (userAbroadNumber) {
        params.push(`UserAbroadNumber=${userAbroadNumber}`);
    }

    if (consigneeId) {
        params.push(`consigneeId=${consigneeId}`);
    }

    if (shipperId) {
        params.push(`shipperId=${shipperId}`);
    }

    if (countryOfOriginId) {
        params.push(`countryOfOriginId=${countryOfOriginId}`);
    }

    if (originState) {
        params.push(`originState=${originState}`);
    }

    if (shippingModeId) {
        params.push(`shippingModeId=${shippingModeId}`);
    }

    if (subModeId) {
        params.push(`subModeId=${subModeId}`);
    }

    if (userLocalId) {
        params.push(`userLocalId=${userLocalId}`);
    }

    if (userAbroadId) {
        params.push(`userAbroadIdOrEmpty=${userAbroadId}`);
    }

    if (dueDate) {
        params.push(`dueDate=${dueDate.split('.')[0].replace('T', ' ')}`);
    }

    if ((pageName === 'orderHistory' || pageName === 'ParcelsHistory' || pageName === 'DropsHistory') && lastUpdateDate) {
        params.push(`lastUpdateDate=${lastUpdateDate.split('.')[0].replace('T', ' ')}`);
    }

    if (reminderDate) {
        params.push(`reminderDate=${reminderDate.split('.')[0].replace('T', ' ')}`);
    }

    if (ilReminderDate) {
        params.push(`ilReminderDate=${ilReminderDate.split('.')[0].replace('T', ' ')}`);
    }

    if (search) {
        params.push(`search=${search}`);
    }

    if (startReminderDate) {
        params.push(`startReminderDate=${startReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (endReminderDate) {
        params.push(`endReminderDate=${endReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (startIlReminderDate) {
        params.push(`startIlReminderDate=${startIlReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (endIlReminderDate) {
        params.push(`endIlReminderDate=${endIlReminderDate.split('.')[0].replace('T', ' ')}`);
    }
    if (stageSearch) {
        params.push(`stageSearch=${stageSearch}`);
    }
    if (stageStateSearch) {
        params.push(`stageStateSearch=${stageStateSearch}`);
    }
    if (reminderDateAdv) {
        params.push(`reminderDateAdv=${reminderDateAdv.split('.')[0].replace('T', ' ')}`);
    }

    if (ilReminderDateAdv) {
        params.push(`ilReminderDateAdv=${ilReminderDateAdv.split('.')[0].replace('T', ' ')}`);
    }

    if (params.length > 0) {
        return params.join('&') + '&';
    }
    return '';
}

export const StyledTableCell = styled(TableCell)({
    /* borderBottom: '2px solid black',*/
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: "2px",
    paddingLeft: "2px",
    maxWidth: "9vw"
});

export const StyledParcelTableCell = styled(TableCell)({
    /*borderBottom: '2px solid black',*/
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: "2px",
    paddingLeft: "2px",
});

export const advancedSearchSelectedStyle = {
    backgroundColor: "grey",
    border: "1px solid blue",
    cursor: "pointer",
    paddingLeft: "5px"
};

export const advancedSearchDefaultStyle = {
    cursor: "pointer",
    paddingLeft: "5px",
    marginLeft: "20px",
};

export const filterServiceProvider = (loginUser, selectedLocationIndex, serviceProviderList) => {
    if (selectedLocationIndex == null)
        return [];
    if (!Array.isArray(serviceProviderList)) {
        return []
    }
    if (selectedLocationIndex == 0)
        return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == 1 || serviceProvider.serviceProviderLocationId == 5 || serviceProvider.serviceProviderLocationId == null));
    if (selectedLocationIndex == 1)
        return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == 4 || serviceProvider.serviceProviderLocationId == 5));
    if (selectedLocationIndex == 3)
        return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == 2 || serviceProvider.serviceProviderLocationId == 5));
    if (selectedLocationIndex == 2)
        return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == 5));

    if ((loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && loginUser?.location?.code === 'US')) {
        return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == 4 || serviceProvider.serviceProviderLocationId == 5));
    }
    return serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == selectedLocationIndex || serviceProvider.serviceProviderLocationId == 5 || serviceProvider.serviceProviderLocationId == null));
}

export const filterServiceProviderImporter = (selectedLocationIndex, serviceProviderList) => {
    return serviceProviderList.length > 0 ? (serviceProviderList?.filter(serviceProvider => (serviceProvider.serviceProviderLocationId == selectedLocationIndex))) : [];
}

export const getPreviousDate = () => {
    var dateOffset = (24 * 60 * 60 * 1000) * 3; //3 days
    var myDate = new Date();
    myDate.setTime(myDate.getTime() - dateOffset);
    return myDate.toISOString();
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const addStatusChatMessage = (order, statusType, status, ilUsers, nlUsers, usUsers, loginUser) => {
    if (order && ilUsers && (nlUsers || usUsers) && loginUser) {
        var userIL = order.userILId;
        var userNL = order?.orderStage ? (order?.orderStage[0]?.orderConfirmationStage[0]?.userNLId) : (order?.parcelStage[0]?.parcelConfirmationStage[0]?.userNLId);
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': statusType + ' status changed to ' + status,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        for (var i = 0; i < lengthIL; i++) {
            if (ilUsers[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUsers[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                }
                else if (ilUsers[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lengthNL; i++) {
                if (nlUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userNL == nlUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                    }
                    else if (nlUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }

            for (var i = 0; i < usUsers.length; i++) {
                if (usUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'US';
                    if (userNL == usUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                    }
                    else if (usUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chat) {
            var chat_list = [];
            chat_list[0] = chat;
            return JSON.stringify(chat_list);
        } else {
            var chat_list = JSON.parse(order.chat);
            chat_list.push(chat);
            return JSON.stringify(chat_list);
        }
    }
    return '';
}
export const getStatusChatMessage = (order, statusType, status, ilUsers, nlUsers, usUsers, loginUser) => {
    if (order && ilUsers && (nlUsers || usUsers) && loginUser) {
        var userIL = order.userILId;
        var userNL = order?.orderStage ? (order?.orderStage[0]?.orderConfirmationStage[0]?.userNLId) : (order?.parcelStage[0]?.parcelConfirmationStage[0]?.userNLId);
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': statusType + ' status changed to ' + status,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        for (var i = 0; i < lengthIL; i++) {
            if (ilUsers[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUsers[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                }
                else if (ilUsers[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lengthNL; i++) {
                if (nlUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userNL == nlUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                    }
                    else if (nlUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }

            for (var i = 0; i < usUsers.length; i++) {
                if (usUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'US';
                    if (userNL == usUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                    }
                    else if (usUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        /*if(!order.chat){
          var chat_list = [];
          chat_list[0] = chat;
          return JSON.stringify(chat_list);
        } else {
          var chat_list = JSON.parse(order.chat);
          chat_list.push(chat);
          return JSON.stringify(chat_list);
        }*/
        return JSON.stringify(chat);
    }
    return '';
}