import axios from 'axios';

export const REQUEST_ACTIONS = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

const getOriginTime = (airStatus, dt) => {
    if (!airStatus)
        return dt;
    var origin = airStatus.originAndDestination.origin;
    var time = dt;
    airStatus.flights.forEach(item => {
        if (item.origin === origin) {
            time = item.atd;
        }
    });
    return time;
}
const getDestinationTime = (airStatus) => {
    var destination = airStatus.originAndDestination.destination;
    var time = null;
    airStatus.flights.forEach(item => {
        if (item.destination === destination) {
            time = item.ata;
        }
    });
    return time;
}
const getPodStatus = (airStatus, status) => {
    var destination = airStatus.originAndDestination.destination;
    airStatus.flights.forEach(item => {
        if (item.destination === destination) {
            if (item.ata)
                return "SUCCESS";
        }
    });
    if (status === "SUCCESS")
        return "IN_PROGRESS";
    return "NOT_STARTED"
}
const compareDateTime = (dt1) => {
    var date1 = new Date(dt1);
    var date2 = new Date();
    if (date1.getTime() < date2.getTime())
        return true;
    return false;
}

export const setOrderData = async function (dis, gord, response, loginUser, failedCallback, callback = null) {
    var resp = JSON.stringify(response);
    var oList = JSON.parse(resp);
    if (oList.length < 1) {
        dis(gord([]));
        return;
    }
    var ids = "";
    oList.forEach(item => { ids += "," + item.id });
    ids = ids.substring(1);
    var orders = [];
    oList.forEach(order => {
        var orderStatus = [];

        var stage0 = {};
        stage0.creationTime = null;
        stage0.updateTime = order.orderStage[0].updateTime;
        stage0.status = order.orderStage[0].status;
        if (order.orderStage[0].orderConfirmationStage[0]) {
            stage0.orderStatusStage = order.orderStage[0].orderConfirmationStage[0];
        } else
            stage0.orderStatusStage = null;
        orderStatus.push(stage0);

        var stage1 = {};
        stage1.creationTime = null;
        stage1.updateTime = null;
        stage1.orderStatusStage = null;
        stage1.status = "IN_PROGRESS";
        if (order.orderStage[1]?.shipperStage) {
            if (order.orderStage[1].shipperStage.length == 1) {
                stage1.orderStatusStage = order.orderStage[1].shipperStage[0];
                stage1.creationTime = order.orderStage[1].shipperStage[0].pickupDate;
                stage1.status = order.orderStage[1].status;
            }
        }
        if (order.orderStage[2].truckingStage[0]) {
            stage1.orderStatusStage = order.orderStage[1].shipperStage[0];
            if (order.orderStage[2].status === "SUCCESS") {
                stage1.creationTime = order.orderStage[2].truckingStage[0].puDate;
            }
            if (order.orderStage[3].status === "SUCCESS" && order.orderStage[4].departureStage[0]) {
                stage1.status = "SUCCESS";
                stage1.updateTime = order.orderStage[4].departureStage[0].whrec;
            }
        }
        orderStatus.push(stage1);

        var stage2 = {};
        stage2.creationTime = null;
        stage2.updateTime = null;
        stage2.orderStatusStage = null;
        stage2.status = "NOT_STARTED";
        if (order.orderStage[2].truckingStage[0] && order.orderStage[2].truckingStage[0]) {
            if (order.orderStage[2].status === "SUCCESS") {
                stage2.status = "SUCCESS";
                stage2.updateTime = order.orderStage[2].truckingStage[0].pickupDate;
                stage2.orderStatusStage = order.orderStage[2].truckingStage[0];
            }
        }
        orderStatus.push(stage2);

        var stage3 = {};
        stage3.creationTime = null;
        stage3.updateTime = null;
        stage3.orderStatusStage = null;
        stage3.status = "NOT_STARTED";
        orderStatus.push(stage3);

        var stage4 = {};
        stage4.creationTime = null;
        stage4.updateTime = order.orderStage[4].updateTime;
        stage4.status = order.orderStage[4].status;
        if (order.incotermsId == 9 || order.incotermsId == 8) {
            if (stage1.status === "SUCCESS") {
                if (stage4.status != "SUCCESS" && stage4.status != "FAILED")
                    stage4.status = "IN_PROGRESS";
            }
        }
        if (order.orderStage[4].departureStage[0]) {
            stage4.orderStatusStage = order.orderStage[4].departureStage[0];
        } else
            stage4.orderStatusStage = null;
        orderStatus.push(stage4);

        var stage5 = {};
        stage5.creationTime = null;
        stage5.updateTime = null;
        if (order.orderStage[4].status === "SUCCESS" && order.orderStage[4].departureStage[0]) {
            stage5.status = "IN_PROGRESS";
            stage5.creationTime = order.orderStage[4].departureStage[0].eta;
        } else {
            stage5.status = "NOT_STARTED";
        }
        stage5.orderStatusStage = null;
        orderStatus.push(stage5);

        var stage6 = {};
        stage6.creationTime = null;
        stage6.updateTime = null;
        stage6.status = "NOT_STARTED";
        stage6.orderStatusStage = null;
        orderStatus.push(stage6);

        var stage7 = {};
        stage7.creationTime = null;
        stage7.updateTime = null;
        stage7.status = "NOT_STARTED";
        stage7.orderStatusStage = null;
        orderStatus.push(stage7);

        var stage8 = {};
        stage8.creationTime = null;
        stage8.updateTime = null;
        stage8.status = "NOT_STARTED";
        stage8.orderStatusStage = null;
        orderStatus.push(stage8);

        order.orderStatus = orderStatus;
        if (order.cstatus && order.shippingModeId != 1) {
            var track = order.cstatus;
            //console.log(track);
            //if (track?.orderId === order.id && order.orderStage[4]?.status === "SUCCESS") {
            if (track?.orderId === order.id) {
                order.airStatus = track;
                if (track?.status) {
                    var d = track;
                    var status = d.status;
                    var keys = [...Object.keys(status)];
                    var data = [];
                    for (var i = 0; i < keys.length; i++) {
                        try {
                            var v = JSON.parse(status[keys[i] + ""].toString());
                            if (v)
                                data.push({ key: keys[i], val: v });
                        } catch (e) { continue; }
                    }
                    data.sort(function (a, b) {
                        return b.key - a.key;
                    });
                    d.status = data[0];
                    var podExists = false;
                    var postpodExists = false;
                    if (d.status.val?.route?.pod?.location?.countryCode === "IL")
                        podExists = true;
                    if (d.status.val?.route?.postpod?.location?.countryCode === "IL")
                        postpodExists = true;
                    if (podExists) {
                        d.arDate1 = d.status.val?.route?.pod?.date ? d.status.val?.route?.pod?.date : 0;
                        d.arPod = d.status.val?.route?.pod?.location?.name;
                        d.arCountry = d.status.val?.route?.pod?.location?.country;
                        d.arLocode = d.status.val?.route?.pod?.location?.locode;
                    }
                    else if (postpodExists) {
                        d.arDate1 = d.status.val?.route?.postpod?.date ? d.status.val?.route?.postpod?.date : 0;
                        d.arPod = d.status.val?.route?.postpod?.location?.name;
                        d.arCountry = d.status.val?.route?.postpod?.location?.country;
                        d.arLocode = d.status.val?.route?.postpod?.location?.locode;
                    }
                    else {
                        d.arDate1 = "";
                        d.arCountry = "";
                        d.arLocode = "";
                    }
                    d.arDate1 = d.arDate1 ? d.arDate1 : d.arDate;
                    d.orgDate = d.arDate1 ? true : false;
                    order.sinay = d;
                } else {
                    if (order.orderStatus[4].status === "SUCCESS")
                        order.orderStatus[5].status = "IN_PROGRESS";
                }
                if (track.statustask) {
                    order.taskyam = track.statustask;
                }
                if (order.sinay?.status?.val?.route?.pol?.location?.countryCode) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[6].status = "IN_PROGRESS";
                    order.orderStatus[5].port = order.sinay?.status?.val?.route?.pol;
                    order.orderStatus[5].updateTime = order.sinay?.status?.val?.route?.pol.date;
                }
                if (order.sinay?.status?.val?.route?.pod?.location?.countryCode === "IL") {
                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.pod;
                    if (order.sinay?.status?.val?.route?.pod.actual) {
                        order.orderStatus[6].status = "SUCCESS";
                    } else {
                        order.orderStatus[6].status = "IN_PROGRESS";
                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.pod.date;
                    }
                } else if (order.sinay?.status?.val?.route?.postpod?.location?.countryCode === "IL") {
                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.postpod;
                    if (order.sinay?.status?.val?.route?.postpod.actual) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = order.sinay?.status?.val?.route?.postpod.date;
                    } else {
                        order.orderStatus[6].status = "IN_PROGRESS";
                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.postpod.date;
                    }
                }
                if (order.sinay?.status?.val && !order.taskyam?.data?.status && order.orderStatus[6].status === "SUCCESS") {
                    order.orderStatus[7].status = "IN_PROGRESS";
                    order.orderStatus[7].creationTime = null;
                } else if (order.taskyam?.data?.status) {
                    if (track.shipmentType === "FCL") {
                        if (order.taskyam?.data?.status == 10) {
                            order.orderStatus[7].status = "SUCCESS";
                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                            order.orderStatus[8].status = "IN_PROGRESS";
                            order.orderStatus[8].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CargoExitDate;
                        } else if (order.taskyam?.data?.status >= 5) {
                            order.orderStatus[7].status = "SUCCESS";
                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                            order.orderStatus[8].status = "IN_PROGRESS";
                        } else if (order.taskyam?.data?.status >= 2) {
                            order.orderStatus[7].status = "IN_PROGRESS";
                            order.orderStatus[8].status = "NOT_STARTED";
                        }
                        if (order?.taskyam?.data?.ContainerInvalidForTooLong) {
                            order.orderStatus[8].status = "FAILED";
                        }
                    } else if (track.shipmentType === "LCL") {
                        if (order.taskyam?.data?.status == 10 && order.taskyam?.data?.lclMap?.lclStatus) {
                            if (order.taskyam?.data?.lclMap?.lclStatus == 6) {
                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                order.orderStatus[7].status = "SUCCESS";
                                order.orderStatus[8].updateTime = order.taskyam?.data?.lclMap?.ExitDepoTime;
                                order.orderStatus[8].status = "SUCCESS";
                            } else if (order.taskyam?.data?.lclMap?.lclStatus >= 3) {
                                order.orderStatus[7].status = "SUCCESS";
                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                order.orderStatus[8].status = "IN_PROGRESS";
                            } else {
                                order.orderStatus[7].status = "IN_PROGRESS";
                                order.orderStatus[8].status = "NOT_STARTED";
                            }
                        } else if (order.taskyam?.data?.status >= 2) {
                            order.orderStatus[7].status = "IN_PROGRESS";
                            order.orderStatus[8].status = "NOT_STARTED";
                        }
                        if (order.orderStatus[7].status === "SUCCESS" || order.orderStatus[7].status === "IN_PROGRESS") {
                            order.orderStatus[6].status = "SUCCESS";
                        }
                    }
                }
                if (order.orderStatus[5].status === "IN_PROGRESS") {
                    if (track.polExit) {
                        order.orderStatus[5].status = "SUCCESS";
                        order.orderStatus[5].updateTime = track.polDate;
                        order.orderStatus[6].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4].departureStage[0]?.etd)) {
                        order.orderStatus[5].status = "FAILED"
                    }
                }
                if (order.orderStatus[6].status === "IN_PROGRESS") {
                    if (track.podExit) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = track.podDate;
                        if (order.orderStatus[7].status != "SUCCESS")
                        order.orderStatus[7].status = "IN_PROGRESS"
                    } else if (compareDateTime(order?.sinay?.arDate1 ? order.sinay.arDate1 : order.orderStage[4].departureStage[0].eta)) {
                        order.orderStatus[6].status = "FAILED"
                    }
                    if (order.taskyam?.data?.status >= 2) {
                        order.orderStatus[6].status = "SUCCESS";
                    }
                }
                if (order.orderStatus[7].status === "IN_PROGRESS") {
                    if (order.taskyam?.data?.status == 1) {
                        order.orderStatus[7].custStatus = "CustomsPink";
                    } else if (order.taskyam?.data?.status == 2) {
                        order.orderStatus[7].custStatus = "CustomsYellow";
                    } else if (order.taskyam?.data?.status == 5) {
                        order.orderStatus[7].custStatus = "CustomsGreen";
                    }
                }
                if (order.customsExit) {
                    order.orderStatus[7].status = "SUCCESS";
                    order.orderStatus[7].updateTime = order.customsDate;
                    if (order.orderStatus[8].status != "SUCCESS") {
                        order.orderStatus[8].status = "IN_PROGRESS";
                    }
                }
            }
            if (order.orderStatus[7].status === "SUCCESS") {
                order.orderStatus[6].status === "SUCCESS";
                order.orderStatus[5].status === "SUCCESS";
            }
            if (order.orderStage[4]?.status !== "SUCCESS") {
                order.orderStatus[5].status = "NOT_STARTED";
                order.orderStatus[6].status = "NOT_STARTED";
                order.orderStatus[7].status = "NOT_STARTED";
                order.orderStatus[8].status = "NOT_STARTED";
            }
        }
        if (order.astatus && order.shippingModeId == 1) {
            var track = order.astatus;
            if (track?.orderId === order.id && order.orderStage[4]?.status === "SUCCESS") {
                order.airStatus = track;
                if (track?.airStatus) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                    order.orderStatus[6].status = getPodStatus(track.airStatus, order.orderStatus[6].status);
                    if (order.orderStatus[6].status === "SUCCESS") {
                        order.orderStatus[6].updateTime = getDestinationTime(track.airStatus);
                    }
                }
                if (track.polExit) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = track.polDate;
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                } else if (order.orderStatus[4].status === "SUCCESS") {
                    order.orderStatus[5].status = "IN_PROGRESS";

                }
                if (track.podExit) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                    order.orderStatus[6].status = "SUCCESS";
                    order.orderStatus[6].updateTime = track.podDate;
                } else if (order.orderStatus[5].status === "SUCCESS") {
                    order.orderStatus[6].status = "IN_PROGRESS";
                }

                if (order.customsExit) {
                    order.orderStatus[7].status = "SUCCESS";
                    order.orderStatus[7].updateTime = order.customsDate;
                } else if (order.orderStatus[6].status === "SUCCESS") {
                    order.orderStatus[7].status = "IN_PROGRESS";
                }
                if (track.importerExit) {
                    order.orderStatus[8].status = "SUCCESS";
                    order.orderStatus[8].updateTime = track.importerDate;
                } else if (order.orderStatus[7].status === "SUCCESS") {
                    order.orderStatus[8].status = "IN_PROGRESS";
                }

                if (order.orderStatus[7].status === "SUCCESS" || order.orderStatus[7].status === "IN_PROGRESS") {
                    order.orderStatus[6].status = "SUCCESS";
                }
                if (order.orderStatus[5].status === "IN_PROGRESS") {
                    if (track.polExit) {
                        order.orderStatus[5].status = "SUCCESS";
                        order.orderStatus[5].updateTime = track.polDate;
                        order.orderStatus[6].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4]?.departureStage[0]?.etd)) {
                        order.orderStatus[5].status = "FAILED"
                    }
                }
                if (order.orderStatus[6].status === "IN_PROGRESS") {
                    if (track.podExit) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = track.podDate;
                        order.orderStatus[7].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4]?.departureStage[0]?.eta)) {
                        order.orderStatus[6].status = "FAILED"
                    }
                }
            }
        }
        orders.push(order);
    });
    dis(gord(orders));
    if (callback != null) {
        callback(orders);
    }
}

export const parseOrderData = (response) => {
    var resp = JSON.stringify(response);
    var oList = JSON.parse(resp);
    if (oList.length < 1) {
        dis(gord([]));
        return;
    }
    var ids = "";
    oList.forEach(item => { ids += "," + item.id });
    ids = ids.substring(1);
    var orders = [];
    oList.forEach(order => {
        var orderStatus = [];
        var stage0 = {};
        stage0.creationTime = null;
        stage0.updateTime = order.orderStage[0].updateTime;
        stage0.status = order.orderStage[0].status;
        if (order.orderStage[0].orderConfirmationStage[0]) {
            stage0.orderStatusStage = order.orderStage[0].orderConfirmationStage[0];
        } else
            stage0.orderStatusStage = null;
        orderStatus.push(stage0);

        var stage1 = {};
        stage1.creationTime = null;
        stage1.updateTime = null;
        stage1.orderStatusStage = null;
        stage1.status = "IN_PROGRESS";
        if (order.orderStage[2].truckingStage[0]) {
            stage1.orderStatusStage = order.orderStage[1].shipperStage[0];
            if (order.orderStage[2].status === "SUCCESS") {
                stage1.creationTime = order.orderStage[2].truckingStage[0].puDate;
            }
            if (order.orderStage[3].status === "SUCCESS" && order.orderStage[4].departureStage[0]) {
                stage1.status = "SUCCESS";
                stage1.updateTime = order.orderStage[4].departureStage[0].whrec;
            }
        }
        orderStatus.push(stage1);

        var stage2 = {};
        stage2.creationTime = null;
        stage2.updateTime = null;
        stage2.orderStatusStage = null;
        stage2.status = "NOT_STARTED";
        if (order.orderStage[2].truckingStage[0] && order.orderStage[2].truckingStage[0]) {
            if (order.orderStage[2].status === "SUCCESS") {
                stage2.status = "SUCCESS";
                stage2.updateTime = order.orderStage[2].truckingStage[0].pickupDate;
                stage2.orderStatusStage = order.orderStage[2].truckingStage[0];
            }
        }
        orderStatus.push(stage2);

        var stage3 = {};
        stage3.creationTime = null;
        stage3.updateTime = null;
        stage3.orderStatusStage = null;
        stage3.status = "NOT_STARTED";
        orderStatus.push(stage3);

        var stage4 = {};
        stage4.creationTime = null;
        stage4.updateTime = order.orderStage[4].updateTime;
        stage4.status = order.orderStage[4].status;
        if (order.orderStage[4].departureStage[0]) {
            stage4.orderStatusStage = order.orderStage[4].departureStage[0];
        } else
            stage4.orderStatusStage = null;
        orderStatus.push(stage4);

        var stage5 = {};
        stage5.creationTime = null;
        stage5.updateTime = null;
        if (order.orderStage[4].status === "SUCCESS" && order.orderStage[4].departureStage[0]) {
            stage5.status = "IN_PROGRESS";
            stage5.creationTime = order.orderStage[4].departureStage[0].eta;
        } else {
            stage5.status = "NOT_STARTED";
        }
        stage5.orderStatusStage = null;
        orderStatus.push(stage5);

        var stage6 = {};
        stage6.creationTime = null;
        stage6.updateTime = null;
        stage6.status = "NOT_STARTED";
        stage6.orderStatusStage = null;
        orderStatus.push(stage6);

        var stage7 = {};
        stage7.creationTime = null;
        stage7.updateTime = null;
        stage7.status = "NOT_STARTED";
        stage7.orderStatusStage = null;
        orderStatus.push(stage7);

        var stage8 = {};
        stage8.creationTime = null;
        stage8.updateTime = null;
        stage8.status = "NOT_STARTED";
        stage8.orderStatusStage = null;
        orderStatus.push(stage8);

        order.orderStatus = orderStatus;
        if (order.cstatus) {
            var track = order.cstatus;
            //console.log(track);
            if (track?.orderId === order.id && order.orderStage[4]?.status === "SUCCESS") {
                order.airStatus = track;
                if (track?.status) {
                    var d = track;
                    var status = d.status;
                    var keys = [...Object.keys(status)];
                    var data = [];
                    for (var i = 0; i < keys.length; i++) {
                        try {
                            var v = JSON.parse(status[keys[i] + ""].toString());
                            if (v)
                                data.push({ key: keys[i], val: v });
                        } catch (e) { continue; }
                    }
                    data.sort(function (a, b) {
                        return b.key - a.key;
                    });
                    d.status = data[0];
                    var podExists = false;
                    var postpodExists = false;
                    if (d.status.val?.route?.pod?.location?.countryCode === "IL")
                        podExists = true;
                    if (d.status.val?.route?.postpod?.location?.countryCode === "IL")
                        postpodExists = true;
                    if (podExists) {
                        d.arDate1 = d.status.val?.route?.pod?.date ? d.status.val?.route?.pod?.date : 0;
                        d.arPod = d.status.val?.route?.pod?.location?.name;
                        d.arCountry = d.status.val?.route?.pod?.location?.country;
                        d.arLocode = d.status.val?.route?.pod?.location?.locode;
                    }
                    else if (postpodExists) {
                        d.arDate1 = d.status.val?.route?.postpod?.date ? d.status.val?.route?.postpod?.date : 0;
                        d.arPod = d.status.val?.route?.postpod?.location?.name;
                        d.arCountry = d.status.val?.route?.postpod?.location?.country;
                        d.arLocode = d.status.val?.route?.postpod?.location?.locode;
                    }
                    else {
                        d.arDate1 = "";
                        d.arCountry = "";
                        d.arLocode = "";
                    }
                    d.arDate1 = d.arDate1 ? d.arDate1 : d.arDate;
                    d.orgDate = d.arDate1 ? true : false;
                    order.sinay = d;
                } else {
                    if (order.orderStatus[4].status === "SUCCESS")
                        order.orderStatus[5].status = "IN_PROGRESS";
                }
                if (track.statustask) {
                    order.taskyam = track.statustask;
                }
                if (order.sinay?.status?.val?.route?.pol?.location?.countryCode) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[6].status = "IN_PROGRESS";
                    order.orderStatus[5].port = order.sinay?.status?.val?.route?.pol;
                    order.orderStatus[5].updateTime = order.sinay?.status?.val?.route?.pol.date;
                }
                if (order.sinay?.status?.val?.route?.pod?.location?.countryCode === "IL") {
                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.pod;
                    if (order.sinay?.status?.val?.route?.pod.actual) {
                        order.orderStatus[6].status = "SUCCESS";
                    } else {
                        order.orderStatus[6].status = "IN_PROGRESS";
                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.pod.date;
                    }
                } else if (order.sinay?.status?.val?.route?.postpod?.location?.countryCode === "IL") {
                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.postpod;
                    if (order.sinay?.status?.val?.route?.postpod.actual) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = order.sinay?.status?.val?.route?.postpod.date;
                    } else {
                        order.orderStatus[6].status = "IN_PROGRESS";
                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.postpod.date;
                    }
                }
                if (order.sinay?.status?.val && !order.taskyam?.data?.status && order.orderStatus[6].status === "SUCCESS") {
                    order.orderStatus[7].status = "IN_PROGRESS";
                    order.orderStatus[7].creationTime = null;
                } else if (order.taskyam?.data?.status) {
                    if (track.shipmentType === "FCL") {
                        if (order.taskyam?.data?.status == 10) {
                            order.orderStatus[7].status = "SUCCESS";
                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                            order.orderStatus[8].status = "IN_PROGRESS";
                            order.orderStatus[8].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CargoExitDate;
                        } else if (order.taskyam?.data?.status >= 5) {
                            order.orderStatus[7].status = "SUCCESS";
                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                            order.orderStatus[8].status = "IN_PROGRESS";
                        } else if (order.taskyam?.data?.status >= 2) {
                            order.orderStatus[7].status = "IN_PROGRESS";
                            order.orderStatus[8].status = "NOT_STARTED";
                        }
                        if (order?.taskyam?.data?.ContainerInvalidForTooLong) {
                            order.orderStatus[8].status = "FAILED";
                        }
                    } else if (track.shipmentType === "LCL") {
                        if (order.taskyam?.data?.status == 10 && order.taskyam?.data?.lclMap?.lclStatus) {
                            if (order.taskyam?.data?.lclMap?.lclStatus == 6) {
                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                order.orderStatus[7].status = "SUCCESS";
                                order.orderStatus[8].updateTime = order.taskyam?.data?.lclMap?.ExitDepoTime;
                                order.orderStatus[8].status = "SUCCESS";
                            } else if (order.taskyam?.data?.lclMap?.lclStatus >= 3) {
                                order.orderStatus[7].status = "SUCCESS";
                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                order.orderStatus[8].status = "IN_PROGRESS";
                            } else {
                                order.orderStatus[7].status = "IN_PROGRESS";
                                order.orderStatus[8].status = "NOT_STARTED";
                            }
                        } else if (order.taskyam?.data?.status >= 2) {
                            order.orderStatus[7].status = "IN_PROGRESS";
                            order.orderStatus[8].status = "NOT_STARTED";
                        }
                        if (order.orderStatus[7].status === "SUCCESS" || order.orderStatus[7].status === "IN_PROGRESS") {
                            order.orderStatus[6].status = "SUCCESS";
                        }
                    }
                }
                if (order.orderStatus[5].status === "IN_PROGRESS") {
                    if (track.polExit) {
                        order.orderStatus[5].status = "SUCCESS";
                        order.orderStatus[5].updateTime = track.polDate;
                        order.orderStatus[6].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4].departureStage[0].etd)) {
                        order.orderStatus[5].status = "FAILED"
                    }
                }
                if (order.orderStatus[6].status === "IN_PROGRESS") {
                    if (track.podExit) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = track.podDate;
                        order.orderStatus[7].status = "IN_PROGRESS"
                    } else if (compareDateTime(order?.sinay?.arDate1 ? order.sinay.arDate1 : order.orderStage[4].departureStage[0].eta)) {
                        order.orderStatus[6].status = "FAILED"
                    }
                    if (order.taskyam?.data?.status >= 2) {
                        order.orderStatus[6].status = "SUCCESS";
                    }
                }
                if (order.orderStatus[7].status === "IN_PROGRESS") {
                    if (order.taskyam?.data?.status == 1) {
                        order.orderStatus[7].custStatus = "CustomsPink";
                    } else if (order.taskyam?.data?.status == 2) {
                        order.orderStatus[7].custStatus = "CustomsYellow";
                    } else if (order.taskyam?.data?.status == 5) {
                        order.orderStatus[7].custStatus = "CustomsGreen";
                    }
                }
                if (order.customsExit) {
                    order.orderStatus[7].status = "SUCCESS";
                    order.orderStatus[7].updateTime = order.customsDate;
                    if (order.orderStatus[8].status != "SUCCESS") {
                        order.orderStatus[8].status = "IN_PROGRESS";
                    }
                }
            }
            if (order.orderStatus[7].status === "SUCCESS") {
                order.orderStatus[6].status === "SUCCESS";
                order.orderStatus[5].status === "SUCCESS";
            }
        }
        if (order.astatus) {
            var track = order.astatus;
            if (track?.orderId === order.id && order.orderStage[4]?.status === "SUCCESS") {
                order.airStatus = track;
                if (track?.airStatus) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                    order.orderStatus[6].status = getPodStatus(track.airStatus, order.orderStatus[6].status);
                    if (order.orderStatus[6].status === "SUCCESS") {
                        order.orderStatus[6].updateTime = getDestinationTime(track.airStatus);
                    }
                }
                if (track.polExit) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = track.polDate;
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                } else if (order.orderStatus[4].status === "SUCCESS") {
                    order.orderStatus[5].status = "IN_PROGRESS";

                }
                if (track.podExit) {
                    order.orderStatus[5].status = "SUCCESS";
                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus, track.polDate);
                    order.orderStatus[6].status = "SUCCESS";
                    order.orderStatus[6].updateTime = track.podDate;
                } else if (order.orderStatus[5].status === "SUCCESS") {
                    order.orderStatus[6].status = "IN_PROGRESS";
                }

                if (order.customsExit) {
                    order.orderStatus[7].status = "SUCCESS";
                    order.orderStatus[7].updateTime = order.customsDate;
                } else if (order.orderStatus[6].status === "SUCCESS") {
                    order.orderStatus[7].status = "IN_PROGRESS";
                }
                if (track.importerExit) {
                    order.orderStatus[8].status = "SUCCESS";
                    order.orderStatus[8].updateTime = track.importerDate;
                } else if (order.orderStatus[7].status === "SUCCESS") {
                    order.orderStatus[8].status = "IN_PROGRESS";
                }

                if (order.orderStatus[7].status === "SUCCESS" || order.orderStatus[7].status === "IN_PROGRESS") {
                    order.orderStatus[6].status = "SUCCESS";
                }
                if (order.orderStatus[5].status === "IN_PROGRESS") {
                    if (track.polExit) {
                        order.orderStatus[5].status = "SUCCESS";
                        order.orderStatus[5].updateTime = track.polDate;
                        order.orderStatus[6].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4].departureStage[0].etd)) {
                        order.orderStatus[5].status = "FAILED"
                    }
                }
                if (order.orderStatus[6].status === "IN_PROGRESS") {
                    if (track.podExit) {
                        order.orderStatus[6].status = "SUCCESS";
                        order.orderStatus[6].updateTime = track.podDate;
                        order.orderStatus[7].status = "IN_PROGRESS"
                    } else if (compareDateTime(order.orderStage[4].departureStage[0].eta)) {
                        order.orderStatus[6].status = "FAILED"
                    }
                }
            }
        }
        orders.push(order);
    });
    return orders;
}

export const getCustomerData = async function (loginUser, failedCallback, setOrderList) {

    var initItems = 0, sItems = 0, tItem = 0, dItem = 0, pItem = 0, cItem = 0, iItem = 0;
    var countryOfOriginData = [];
    sendRequest(`/api/fa/${loginUser?.customer?.id}`, REQUEST_ACTIONS.GET, '', {
        successCallback: obj => {
            var resp = JSON.stringify(obj);
            var oList = JSON.parse(resp);
            if (oList.length < 1) {
                setOrderList([]);
                return;
            }
            sendRequest(`/api/track/getAllContainerTracksByCustomer/${loginUser?.customer?.companyName.trim()}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response1 => {
                    var tracks = response1.cTracks;
                    sendRequest(`/api/track/getAllAirTracksByCustomer/${loginUser?.customer?.companyName.trim()}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response1 => {
                            var atracks = response1.cTracks;
                            var orders = [];
                            sendRequest('/api/countryOfOrigin', REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    countryOfOriginData = response;
                                    oList.forEach(order => {
                                        var orderStatus = [];

                                        var stage0 = {};
                                        stage0.creationTime = null;
                                        stage0.updateTime = order.orderStage[0].updateTime;
                                        stage0.status = order.orderStage[0].status;
                                        if (order.orderStage[0].orderConfirmationStage[0]) {
                                            stage0.orderStatusStage = order.orderStage[0].orderConfirmationStage[0];
                                        } else
                                            stage0.orderStatusStage = null;
                                        orderStatus.push(stage0);

                                        var stage1 = {};
                                        stage1.creationTime = null;
                                        stage1.updateTime = null;
                                        stage1.orderStatusStage = null;
                                        stage1.status = "IN_PROGRESS";
                                        if (order.orderStage[2].truckingStage[0]) {
                                            stage1.orderStatusStage = order.orderStage[1].shipperStage[0];
                                            if (order.orderStage[2].status === "SUCCESS") {
                                                stage1.creationTime = order.orderStage[2].truckingStage[0].puDate;
                                            }
                                            if (order.orderStage[4].status === "SUCCESS") {
                                                stage1.status = "SUCCESS";
                                                stage1.updateTime = order.orderStage[4].departureStage[0].whrec;
                                            }
                                        }
                                        orderStatus.push(stage1);

                                        var stage2 = {};
                                        stage2.creationTime = null;
                                        stage2.updateTime = null;
                                        stage2.orderStatusStage = null;
                                        stage2.status = "NOT_STARTED";
                                        if (order.orderStage[2].truckingStage[0]) {
                                            if (order.orderStage[2].status === "SUCCESS") {
                                                stage2.status = "SUCCESS";
                                                stage2.updateTime = order.orderStage[2].truckingStage[0].pickupDate;
                                                stage2.orderStatusStage = order.orderStage[2].truckingStage[0];
                                            }
                                        }
                                        orderStatus.push(stage2);

                                        var stage3 = {};
                                        stage3.creationTime = null;
                                        stage3.updateTime = null;
                                        stage3.orderStatusStage = null;
                                        stage3.status = "NOT_STARTED";
                                        orderStatus.push(stage3);

                                        var stage4 = {};
                                        stage4.creationTime = null;
                                        stage4.updateTime = order.orderStage[4].updateTime;
                                        stage4.status = order.orderStage[4].status;
                                        if (order.orderStage[4].departureStage[0]) {
                                            stage4.orderStatusStage = order.orderStage[4].departureStage[0];
                                        } else
                                            stage4.orderStatusStage = null;
                                        orderStatus.push(stage4);

                                        var stage5 = {};
                                        stage5.creationTime = null;
                                        stage5.updateTime = null;
                                        if (order.orderStage[4].status !== "NOT_STARTED" && order.orderStage[4].departureStage[0]) {
                                            stage5.status = "IN_PROGRESS";
                                            stage5.creationTime = order.orderStage[4].departureStage[0].eta;
                                        } else {
                                            stage5.status = "NOT_STARTED";
                                        }
                                        stage5.orderStatusStage = null;
                                        orderStatus.push(stage5);

                                        var stage6 = {};
                                        stage6.creationTime = null;
                                        stage6.updateTime = null;
                                        stage6.status = "NOT_STARTED";
                                        stage6.orderStatusStage = null;
                                        orderStatus.push(stage6);

                                        var stage7 = {};
                                        stage7.creationTime = null;
                                        stage7.updateTime = null;
                                        stage7.status = "NOT_STARTED";
                                        stage7.orderStatusStage = null;
                                        orderStatus.push(stage7);

                                        var stage8 = {};
                                        stage8.creationTime = null;
                                        stage8.updateTime = null;
                                        stage8.status = "NOT_STARTED";
                                        stage8.orderStatusStage = null;
                                        orderStatus.push(stage8);

                                        order.orderStatus = orderStatus;
                                        tracks.forEach(track => {
                                            if (track.orderId === order.id) {
                                                if (track.status) {
                                                    var d = track;
                                                    var status = d.status;
                                                    var keys = [...Object.keys(status)];
                                                    var data = [];
                                                    for (var i = 0; i < keys.length; i++) {
                                                        try {
                                                            var v = JSON.parse(status[keys[i] + ""].toString());
                                                            if (v)
                                                                data.push({ key: keys[i], val: v });
                                                        } catch (e) { continue; }
                                                    }
                                                    data.sort(function (a, b) {
                                                        return b.key - a.key;
                                                    });
                                                    d.status = data[0];
                                                    var podExists = false;
                                                    var postpodExists = false;
                                                    if (d.status.val?.route?.pod?.location?.countryCode === "IL")
                                                        podExists = true;
                                                    if (d.status.val?.route?.postpod?.location?.countryCode === "IL")
                                                        postpodExists = true;
                                                    if (podExists) {
                                                        d.arDate1 = d.status.val?.route?.pod?.date ? d.status.val?.route?.pod?.date : 0;
                                                        d.arPod = d.status.val?.route?.pod?.location?.name;
                                                        d.arCountry = d.status.val?.route?.pod?.location?.country;
                                                        d.arLocode = d.status.val?.route?.pod?.location?.locode;
                                                    }
                                                    else if (postpodExists) {
                                                        d.arDate1 = d.status.val?.route?.postpod?.date ? d.status.val?.route?.postpod?.date : 0;
                                                        d.arPod = d.status.val?.route?.postpod?.location?.name;
                                                        d.arCountry = d.status.val?.route?.postpod?.location?.country;
                                                        d.arLocode = d.status.val?.route?.postpod?.location?.locode;
                                                    }
                                                    else {
                                                        d.arDate1 =
                                                            d.arCountry = "";
                                                        d.arLocode = "";
                                                    }
                                                    d.arDate1 = d.arDate1 ? d.arDate1 : d.arDate;
                                                    d.orgDate = d.arDate1 ? true : false;
                                                    order.sinay = d;
                                                } else {
                                                    if (order.orderStatus[4].status === "SUCCESS")
                                                        order.orderStatus[6].status = "IN_PROGRESS";
                                                }
                                                if (track.statustask) {
                                                    order.taskyam = track.statustask;
                                                }
                                                if (order.sinay?.status?.val?.route?.pol?.location?.countryCode) {
                                                    order.orderStatus[5].status = "SUCCESS";
                                                    order.orderStatus[5].port = order.sinay?.status?.val?.route?.pol;
                                                    order.orderStatus[5].updateTime = order.sinay?.status?.val?.route?.pol.date;
                                                }
                                                if (order.sinay?.status?.val?.route?.pod?.location?.countryCode === "IL") {
                                                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.pod;
                                                    if (order.sinay?.status?.val?.route?.pod.actual) {
                                                        order.orderStatus[6].status = "SUCCESS";
                                                        order.orderStatus[6].updateTime = order.sinay?.status?.val?.route?.pod.date;
                                                    } else {
                                                        order.orderStatus[6].status = "IN_PROGRESS";
                                                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.pod.date;
                                                    }
                                                } else if (order.sinay?.status?.val?.route?.postpod?.location?.countryCode === "IL") {
                                                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.postpod;
                                                    if (order.sinay?.status?.val?.route?.postpod.actual) {
                                                        order.orderStatus[6].status = "SUCCESS";
                                                        order.orderStatus[6].updateTime = order.sinay?.status?.val?.route?.postpod.date;
                                                    } else {
                                                        order.orderStatus[6].status = "IN_PROGRESS";
                                                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.postpod.date;
                                                    }
                                                } else {
                                                    order.orderStatus[6].port = order.sinay?.status?.val?.route?.pod;
                                                    if (order.sinay?.status?.val?.route?.pod.actual) {
                                                        order.orderStatus[6].status = "SUCCESS";
                                                        order.orderStatus[6].updateTime = order.sinay?.status?.val?.route?.pod.date;
                                                    } else {
                                                        order.orderStatus[6].status = "IN_PROGRESS";
                                                        order.orderStatus[6].creationTime = order.sinay?.status?.val?.route?.pod.date;
                                                    }
                                                }
                                                if (order.sinay?.status?.val && !order.taskyam?.data?.status && order.orderStatus[6].status === "SUCCESS") {
                                                    order.orderStatus[7].status = "IN_PROGRESS";
                                                    order.orderStatus[7].creationTime = null;
                                                } else if (order.taskyam?.data?.status) {
                                                    order.orderStatus[5].status = "SUCCESS";
                                                    order.orderStatus[6].status = "SUCCESS";
                                                    if (track.shipmentType === "FCL") {
                                                        if (order.taskyam?.data?.status == 10) {
                                                            order.orderStatus[7].status = "SUCCESS";
                                                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                                                            order.orderStatus[8].status = "SUCCESS";
                                                            order.orderStatus[8].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CargoExitDate;
                                                        } else if (order.taskyam?.data?.status >= 5) {
                                                            order.orderStatus[7].status = "SUCCESS";
                                                            order.orderStatus[7].updateTime = order.taskyam?.data?.CargoList?.ImportProcess?.CustomsReleaseDate;
                                                            order.orderStatus[8].status = "IN_PROGRESS";
                                                        } else if (order.taskyam?.data?.status >= 2) {
                                                            order.orderStatus[7].status = "IN_PROGRESS";
                                                            order.orderStatus[8].status = "NOT_STARTED";
                                                        }
                                                        if (order?.taskyam?.data?.ContainerInvalidForTooLong) {
                                                            order.orderStatus[8].status = "IN_PROGRESS";
                                                        }
                                                    } else if (track.shipmentType === "LCL") {
                                                        if (order.taskyam?.data?.status == 10 && order.taskyam?.data?.lclMap?.lclStatus) {
                                                            if (order.taskyam?.data?.lclMap?.lclStatus == 6) {
                                                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                                                order.orderStatus[7].status = "SUCCESS";
                                                                order.orderStatus[8].updateTime = order.taskyam?.data?.lclMap?.ExitDepoTime;
                                                                order.orderStatus[8].status = "SUCCESS";
                                                            } else if (order.taskyam?.data?.lclMap?.lclStatus >= 3) {
                                                                order.orderStatus[7].status = "SUCCESS";
                                                                order.orderStatus[7].updateTime = order.taskyam?.data?.lclMap?.CustomsReleaseDepoDate;
                                                                order.orderStatus[8].status = "IN_PROGRESS";
                                                            } else {
                                                                order.orderStatus[7].status = "IN_PROGRESS";
                                                                order.orderStatus[8].status = "NOT_STARTED";
                                                            }
                                                        } else if (order.taskyam?.data?.status >= 2) {
                                                            order.orderStatus[7].status = "IN_PROGRESS";
                                                            order.orderStatus[8].status = "NOT_STARTED";
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                        atracks.forEach(track => {
                                            if (track.orderId === order.id) {
                                                if (track.airStatus) {
                                                    order.orderStatus[5].status = "SUCCESS";
                                                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus);
                                                    order.airStatus = track;
                                                    order.orderStatus[6].status = getPodStatus(track.airStatus, order.orderStatus[5].status);
                                                    if (order.orderStatus[6].status === "SUCCESS") {
                                                        order.orderStatus[6].updateTime = getDestinationTime(track.airStatus);
                                                    }
                                                }
                                                if (track.podExit) {
                                                    order.orderStatus[5].status = "SUCCESS";
                                                    order.airStatus = track;
                                                    order.orderStatus[5].updateTime = getOriginTime(track.airStatus);
                                                    order.orderStatus[6].status = "SUCCESS";
                                                    order.orderStatus[6].updateTime = track.podDate;
                                                } else if (order.orderStatus[4].status === "SUCCESS") {
                                                    order.orderStatus[6].status = "IN_PROGRESS";
                                                }
                                                if (track.customsExit) {
                                                    order.orderStatus[7].status = "SUCCESS";
                                                    order.orderStatus[7].updateTime = track.customsDate;
                                                } else if (order.orderStatus[6].status === "SUCCESS") {
                                                    order.orderStatus[7].status = "IN_PROGRESS";
                                                }
                                                if (track.importerExit) {
                                                    order.orderStatus[8].status = "SUCCESS";
                                                    order.orderStatus[8].updateTime = track.importerDate;
                                                } else if (order.orderStatus[7].status === "SUCCESS") {
                                                    order.orderStatus[8].status = "IN_PROGRESS";
                                                }
                                            }
                                        });
                                        if (order.orderStatus[8].status === "IN_PROGRESS") {
                                            var address;
                                            if (order.consignee.address1)
                                                address = order.consignee.address1;
                                            if (order.consignee.city)
                                                address += " " + order.consignee.city;
                                            if (order.consignee.countryId)
                                                address += " " + countryOfOriginData.find(country => country.id === order.consignee.countryId)?.name || '';
                                            order.mapAddress = address;
                                            order.mapAddType = 8;
                                            order.mapStatus = 8;
                                        } else if (order.orderStatus[7].status === "IN_PROGRESS") {
                                            var address = "";
                                            if (order.consignee.address1)
                                                address = order.consignee.address1;
                                            if (order.consignee.city)
                                                address += " " + order.consignee.city;
                                            if (order.consignee.countryId)
                                                address += " " + countryOfOriginData.find(country => country.id === order.consignee.countryId)?.name || '';
                                            order.mapAddress = address;
                                            order.mapAddType = 8;
                                            order.mapStatus = 7;
                                        } else if (order.orderStatus[6].status === "IN_PROGRESS") {
                                            if (order.sinay?.status?.val?.route?.pod?.location?.coordinates) {
                                                order.mapLocation = order.sinay.status.val.route.pod.location.coordinates;
                                                order.mapAddType = 6;
                                            }
                                            else {
                                                var address;
                                                if (order.shipper.address1)
                                                    address = order.shipper.address1;
                                                if (order.shipper.city)
                                                    address += " " + order.shipper.city;
                                                if (order.consignee.countryId)
                                                    address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';
                                                order.mapAddress = address;
                                                order.mapAddType = 8;
                                            }
                                            order.mapStatus = 6;
                                        } else if (order.orderStatus[5].status === "SUCCESS") {
                                            if (order.sinay?.status?.val?.routeData?.coordinates) {
                                                order.mapLocation = order.sinay.status.val.routeData.coordinates;
                                                order.mapAddType = 6;
                                            }
                                            else {
                                                var address;
                                                if (order.shipper.address1)
                                                    address = order.shipper.address1;
                                                if (order.shipper.city)
                                                    address += " " + order.shipper.city;
                                                if (order.consignee.countryId)
                                                    address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';
                                                order.mapAddress = address;
                                                order.mapAddType = 8;
                                            }
                                            order.mapStatus = 5;
                                        } else if (order.orderStatus[4].status === "SUCCESS") {
                                            if (order.sinay?.status?.val?.route?.pol?.location?.coordinates) {
                                                order.mapLocation = order.sinay.status.val.route.pol.location.coordinates;
                                                order.mapAddType = 6;
                                            }
                                            else {
                                                var address;
                                                if (order.shipper.address1)
                                                    address = order.shipper.address1;
                                                if (order.shipper.city)
                                                    address += " " + order.shipper.city;
                                                if (order.consignee.countryId)
                                                    address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';
                                                order.mapAddress = address;
                                                order.mapAddType = 8;
                                            }
                                            order.mapStatus = 5;
                                        } else if (order.orderStatus[3].status === "SUCCESS") {
                                            var address;
                                            if (order.shipper.address1)
                                                address = order.shipper.address1;
                                            if (order.shipper.city)
                                                address += " " + order.shipper.city;
                                            if (order.consignee.countryId)
                                                address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';

                                            order.mapAddress = address;
                                            order.mapAddType = 8;
                                            order.mapStatus = 3;
                                        } else if (order.orderStatus[1].status === "SUCCESS") {
                                            var address = "";
                                            if (order.shipper.address1)
                                                address = order.shipper.address1;
                                            if (order.shipper.city)
                                                address += " " + order.shipper.city;
                                            if (order.consignee.countryId)
                                                address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';

                                            order.mapAddress = address;
                                            order.mapAddType = 8;
                                            order.mapStatus = 1;
                                        }
                                        else {
                                            var address = "";
                                            if (order.shipper.address1)
                                                address = order.shipper.address1;
                                            if (order.shipper.city)
                                                address += " " + order.shipper.city;
                                            if (order.consignee.countryId)
                                                address += " " + countryOfOriginData.find(country => country.id === order.shipper.countryId)?.name || '';
                                            //
                                            order.mapAddress = address;
                                            order.mapAddType = 8;
                                            order.mapStatus = 0;
                                        }

                                        if (order.mapStatus == 1) {
                                            sItems++;
                                        } else if (order.mapStatus == 3) {
                                            tItem++;
                                        } else if (order.mapStatus == 5) {
                                            dItem++;
                                        } else if (order.mapStatus == 6) {
                                            pItem++;
                                        } else if (order.mapStatus == 7) {
                                            cItem++;
                                        } else if (order.mapStatus == 8) {
                                            iItem++;
                                        } if (order.mapStatus == 0) {
                                            initItems++;
                                        }
                                        orders.push(order);
                                    });
                                    setOrderList(orders);
                                },
                                failedCallback,
                            });
                        },
                        failedCallback,
                    },
                    );
                },
                failedCallback,
            },
            );
        },
        failedCallback,
    });
}


/* Rest API call send request
  @param url: request URL
  @param method: Request type (GET | POST | PUT | DELETE)
  @param body: Post | Put | Delete body request in object format
  @param options: contain successCallback | failedCallback to handle request result
*/
export const sendRequest = async function (url, method, body, options) {
  try {
    const authToken = getCookie('Authorization');
    let reqBody = '';

    if (method === REQUEST_ACTIONS.POST || method === REQUEST_ACTIONS.PUT || method === REQUEST_ACTIONS.DELETE) {
      reqBody = body;
    }

    const settings = {
      method: method,
      url: url,
      data: reqBody,
    };

    if (authToken) {
      settings['headers'] = {
        Authorization: authToken,
      };
    }

    const result = await axios.request(settings);
    if (options?.successCallback) {
      return options.successCallback(result.data);
    }

    return result.data;
  } catch (error) {
    if (error?.response && options?.failedCallback) {
      return options.failedCallback(error.response);
    }
      if (options?.failedCallback)
    return options.failedCallback(error);
  }
};

const getCookie = function (name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0, cnt = cookies.length; i < cnt; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const send_data_get = (url, fileName) => {
    fetch(url)
        .then(response =>
            response.blob()
        )
        .then(response => {
            const blob = new Blob([response], { type: 'application/pdf' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = downloadUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
        })
}
