/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback, Fragment } from 'react';
import { Dialog } from 'style-guide';
import './ShipmentDataRow.scss';
import shipIcon from "../../../../resources/ship_icon.svg"
import airIcon from "../../../../resources/air.png"
import lclIcon from "../../../../resources/lcl.png"
import fclIcon from "../../../../resources/fcl.svg"
import fcllclIcon from "../../../../resources/fclclc.svg"
import arrowLeft from "../../../../resources/arrowLeft.png"
import invoice from "../../../../resources/invoice.png"
import details from "../../../../resources/details.png"
import shipper from "../../../../resources/shipper.png"
import shipper_nt from "../../../../resources/shipper_white.png"
import wh from "../../../../resources/wh.png"
import wh_nt from "../../../../resources/wh_white.png"
import departure_air from "../../../../resources/departure.png"
import departure_air_nt from "../../../../resources/departure_white.png"
import departure_sea from "../../../../resources/departure_sea.png"
import departure_sea_nt from "../../../../resources/departure_sea_white.png"
import destination_air from "../../../../resources/arrival.png"
import destination_air_nt from "../../../../resources/arrival_white.png"
import destination_sea from "../../../../resources/arrival_sea.png"
import destination_sea_nt from "../../../../resources/arrival_sea_white.png"
import customs from "../../../../resources/customs.png"
import customs_nt from "../../../../resources/customs_white.png"
import delivered from "../../../../resources/delivered.png"
import delivered_nt from "../../../../resources/delivered_white.png"
import chatButton from "../../../../resources/chat.svg"
import chatButton1 from "../../../../resources/chatWhite.svg"
//import chatButton from "../../../../resources/chatButton.png"
//import chatButton1 from "../../../../resources/chatButton1.png"
//import invoiceButton from "../../../../resources/invoiceButton.png"
import invoiceButton from "../../../../resources/invoice.svg"
//import detailButton from "../../../../resources/detailsButton.png"
import detailButton from "../../../../resources/details.svg"
import chatIcon from "../../../../resources/chat_icon.svg"
import LocMarker from "../../../../resources/pin.png"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkChatRead';
import ChatIcon from '@mui/icons-material/ChatBubble';
// import ChatIcon from "../../../../resources/chat_icon.svg"
import { TableContainer, Table, TableBody, TableRow, TableCell, Box, Grid, Button, CircularProgress } from '@mui/material';
import { TABS } from 'constants/global-constants';
import OrderBookMark from '../OrderBookMark';
import { getStatus } from '../commonUtility';
import { Tooltip } from 'style-guide';

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

const noteStyle = {
    backgroundColor: "#2e7d329c",
    padding: "3px",
    borderRadius: "5px",
    width: '1.6vw !important',
    height: '1.6vw !important',
};

const greenStyle = {
    backgroundColor: "#2e7d329c",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important',
    height: '1.6vw !important',
};
const greyStyle = {
    backgroundColor: "white",
    padding: "3px",
    borderRadius: "1px",
    border: "2px solid grey",
    width: '1.6vw !important',
    height: '1.6vw !important',
};
const blueStyle = {
    backgroundColor: "#1976d270",
    color: "#0c65c1",
    padding: "3px",
    borderRadius: "1px",
    border: "1px solid #0c65c1",
    width: '1.6vw !important',
    height: '1.6vw !important',
};
const orangeStyle = {
    backgroundColor: "#feb7348f",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important',
    height: '1.6vw !important',
};

const pastelStyle = {
    backgroundColor: "#F2A2E8",
    padding: "3px",
    borderRadius: "5px",
    border: "2px solid grey",
    width: '1.6vw !important',
    height: '1.6vw !important',
};

const defaultStyle = {
    backgroundColor: "white",
    color: "#0c65c1",
    padding: "3px",
    borderRadius: "1px",
    border: "1px solid #0c65c1",
    width: '1.6vw !important',
    height: '1.6vw !important',
};
const ShipmentDataRow = ({ showImportsExport, showImports, orders, isLoading, polAir, podAir, polSea, podSea, cOrigin, isBookmark = false, orderDetailHandle }) => {


    const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const getDate = (d) => {
        var date = new Date(d);
        var month = date.getMonth(date) + 1;
        var day = date.getDate(date);
        var year = date.getFullYear(date);

        return `${day}/${month}/${year}`;
    }
    const getTime = (d) => {
        var date = new Date(d);
        var hr = date.getHours(date);
        var min = date.getMinutes(date);

        return `${hr}:${min}`;
    }

    const getCountryName = (order) => {
        var cname = cOrigin.find(ctr => ctr.id == order.countryOfOriginId);
        return cname ? cname.name : "";
    }
    const getPOD = (order) => {
        var custDate = order.airStatus?.podDate ? order.airStatus?.podDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null)
        var dt = order?.sinay?.arDate1 ? order.sinay.arDate1 : custDate;
        return formatDateTime(dt);
    }

    const chatRead = (order) => {
        if (order.chatCustomer == null)
            return true;
        var chat = JSON.parse(order.chatCustomer);
        if (!chat)
            return true;
        for (var i = 0; i < chat.length; i++) {
            if (!chat[i].NLStatus)
                return false;
        }
        return true;
    }

    const shipTransDetails = (order) => {
        return <Box className='ship-trans'>
            <Grid className="font-heebo" style={{ color: "#959494", "-webkit-text-stroke": "thin", fontSize: "14px", padding: "0 1.8vw", minWidth: "11.7vw" }}>
                {order.shipper.companyShortName && <Box className='pickup mr-5' style={{ fontSize: "0.8vw", color: "#707070", "-webkit-text-stroke": "thin" }}>{order.shipper?.companyShortName ? order.shipper.companyShortName : "-"}</Box>}
                {(order.countryOfOriginId && order.poaId) && <Grid item container alignItems="center" justifyContent="center" className='pickup mr-5' style={{ fontSize: "1vw" }}><span>{getCountryName(order)}</span> <span><img src={arrowLeft} style={{width: "1.5vw", margin:"0 0.5vw"}} /></span> <span>{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name}</span></Grid>}
                {/*{<Box className='pickup mr-5'>{getStatus(order)}</Box>}*/}
                {/*{<Box className='pickup mr-5'>{getLastEvent(order)}</Box>}*/}
                {<Grid item container className='shipment-action' alignItems="center" justifyContent="center" flexWrap="nowrap" flexDirection="row-reverse" style={{ maxWidth: "12vw"}}>
                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, TABS[4])} style={{ maxWidth: "3vw", cursor: "pointer", color: `${chatRead(order) ? "#707070" : "white"}`, background: `${chatRead(order) ? "white" : "#00aeef"}` }} alignItems="center" flexWrap="nowrap">
                        <img src={chatRead(order) ? chatButton : chatButton1} /> <span style={{ marginRight: "3px" }}>Chat</span>
                    </Grid>
                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, TABS[0])} style={{ maxWidth: "4vw", cursor: "pointer" }} alignItems="center" flexWrap="nowrap">
                        <img src={detailButton} /> <span style={{ marginRight: "3px" }}>Details</span>
                    </Grid>
                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, TABS[2])} style={{ maxWidth: "4vw", cursor: "pointer" }} alignItems="center" flexWrap="nowrap">
                        <img src={invoiceButton} /> <span style={{ marginRight: "3px" }}>Invoice</span>
                    </Grid>
                </Grid>}
            </Grid>
        </Box>;
    };
    const getInitiatorStyle = (ch) => {
        var type = ch['initiatorType']
        if (type == 5)
            return nlChatNameStyle;
        else
            return ilChatNameStyle;
    }

    const getCustChatView = (order) => {
        return chatRead(order) ? defaultStyle : defablueStyleultStyle;
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            if (chat_list?.length) {
                var len = chat_list.length;
                if (chat_list[len - 1]['NLStatus'])
                    return true;
                return false;
            }
        }
        return false;
    }
    const getCustToolTip = (order) => {
        if (order['chatCustomer']) {
            var chat_list = JSON.parse(order['chatCustomer'])
            if (chat_list?.length) {
                var len = chat_list.length;
                return chat_list[len - 1]['text'];
            }
        }
        return '';
    }

    const getShipperStatus_color = (order) => {
        if (order.orderStatus[1].status === "SUCCESS")
            return "#2fadee";
        else {
            if (order.orderStatus[3].status === "SUCCESS" || order.orderStatus[3].status === "IN_PROGRESS")
                return "#2fadee";
            else
                return "3c5293";
        }
    }
    const getShipperStatus_image = (order) => {
        if (order.orderStatus[1].status === "SUCCESS")
            return shipper_nt;
        else {
            if (order.orderStatus[3].status === "SUCCESS" || order.orderStatus[3].status === "IN_PROGRESS")
                return shipper_nt;
            else
                return shipper;
        }
    }
    const getWHStatus_color = (order) => {
        if (order.orderStatus[3].status === "SUCCESS")
            return "#2fadee";
        else if (order.orderStatus[3].status === "IN_PROGRESS")
            return "#3c5293";
        else {
            if (order.orderStatus[1].status === "SUCCESS")
                return "#3c5293";
            return "";
        }
    }
    const getWHStatus_image = (order) => {
        if (order.orderStatus[3].status === "SUCCESS")
            return wh_nt;
        else if (order.orderStatus[3].status === "IN_PROGRESS")
            return wh_nt;
        else {
            if (order.orderStatus[1].status === "SUCCESS")
                return wh_nt;
            return wh;
        }
    }
    const getPOLStatus = (order) => {
        if (order.orderStatus[3].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "done";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "inprogress";
            }
            else
                return "inprogress";
        }
        else
            return ""
    }
    const getPOLStatus_color = (order) => {
        if (order.orderStatus[3].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return "#2fadee";
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return "#3c5293";
            }
            else
                return "#3c5293";
        }
        else
            return ""
    }
    const getPOLStatus_image = (order) => {
        if (order.orderStatus[3].status === "SUCCESS" || getLastStatusNum(order) >= 3) {
            if (order.orderStatus[5].status) {
                if (order.orderStatus[5].status === "SUCCESS")
                    return order.shippingModeId == 1 ? departure_air_nt : departure_sea_nt;
                else (order.orderStatus[5].status === "IN_PROGRESS")
                return order.shippingModeId == 1 ? departure_air_nt : departure_sea_nt;
            }
            else
                return order.shippingModeId == 1 ? departure_air : departure_sea;
        }
        else
            return order.shippingModeId == 1 ? departure_air : departure_sea
    }
    const getPODStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS") {
            if (order.orderStatus[5].status === "SUCCESS") {
                if (order.orderStatus[6]?.status) {
                    if (order.orderStatus[6]?.status === "SUCCESS")
                        return "done";
                    else if (order.orderStatus[6]?.status === "IN_PROGRESS")
                        return "inprogress";
                    else return "";
                }
                else
                    return "inprogress";
            }
            else
                return "";
        }
        else
            return "";
    }
    const getPODStatus_color = (order) => {
        if (order.orderStatus[5].status === "SUCCESS") {
            if (order.orderStatus[6]?.status) {
                if (order.orderStatus[6]?.status === "SUCCESS")
                    return "#2fadee";
                else return "#3c5293";
            }
            else
                return "#3c5293";
        }
        else
            return "";
    }
    const getPODStatus_image = (order) => {
        if (order.orderStatus[5].status === "SUCCESS") {
            if (order.orderStatus[6]?.status) {
                if (order.orderStatus[6]?.status === "SUCCESS" || order.orderStatus[6]?.status === "IN_PROGRESS")
                    return order.shippingModeId == 1 ? destination_air_nt : destination_sea_nt;
                else return order.shippingModeId == 1 ? destination_air : destination_sea;
            }
            else
                return order.shippingModeId == 1 ? destination_air : destination_sea;
        }
        else
            return order.shippingModeId == 1 ? destination_air : destination_sea;
    }
    const getCustomsStatus = (order) => {
        if (order.orderStatus[4].status === "SUCCESS" && order.orderStatus[5].status === "SUCCESS" && order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[7]?.status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getCustomsStatus_color = (order) => {
        if (order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS") {
                return "#2fadee";
            } else
                return "#3c5293";
        }
        else
            return ""
    }
    const getCustomsStatus_image = (order) => {
        if (order.orderStatus[6].status === "SUCCESS") {
            if (order.orderStatus[7]?.status === "SUCCESS" || order.orderStatus[7]?.status === "IN_PROGRESS") {
                return customs_nt;
            } else
                return customs;
        }
        else
            return customs
    }
    const getImportStatus = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return "done";
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return "inprogress";
            } else
                return "";
        }
        else
            return ""
    }
    const getImportStatus_color = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return "#2fadee";
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return "#3c5293";
            } else
                return "#3c5293";
        }
        else
            return ""
    }
    const getImportStatus_image = (order) => {
        if (order.orderStatus[7].status === "SUCCESS") {
            if (order.orderStatus[8]?.status === "SUCCESS") {
                return delivered_nt;
            } else if (order.orderStatus[8]?.status === "IN_PROGRESS") {
                return delivered_nt;
            } else
                return delivered;
        }
        else
            return delivered
    }

    const [showCustomsDialog, setShowCustomsDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const openCustomsDialog = (order) => {
        if (order?.taskyam?.data?.CargoList?.ImportProcess) {
            setSelectedOrder(order)
            setShowCustomsDialog(true)
        }
    }
    const closeCustomsDialog = () => { setShowCustomsDialog(false) }

    const statusProgressBar = (order) => {
        return <Grid className='progress-bar' style={{ }}>

            {/* Shipper Status */}
            <Box className={`progress-step ${(order.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'}`}>
                
                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getShipperStatus_color(order)}`, width: "2.36vw", height: "2.36vw", marginBottom: "0.5vw" }}>
                        <img src={getShipperStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Shipper'}
                </Box>
                {order?.orderStatus[1]?.status && <Box className='prog-dec'>
                    {((order.orderStatus[1].status === 'FAILED' || order.orderStatus[1].status === 'NOT_STARTED') ? '' : (order.orderStatus[1].status === 'SUCCESS' ? formatDateTime(order.orderStatus[1].updateTime) : ""))}{/*order.orderStatus[1].status.replace(/_/g, " ")*/}
                </Box>}
            </Box>

            <Box className={`progress-step ${getPOLStatus(order)}`}>
                
                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPOLStatus_color(order)}`, width: "2.36vw", height: "2.36vw", marginBottom: "0.5vw" }}>
                        <img src={getPOLStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Departure'}
                </Box>
                {(order?.orderStatus[4]?.status === 'SUCCESS') && <Box className='prog-dec'>
                    {order?.orderStatus[5].status === 'SUCCESS' ? formatDateTime(order.orderStatus[5].updateTime) : (order?.orderStatus[5].status === 'IN_PROGRESS' ? (order.orderStatus[5].creationTime ?  formatDateTime(order.orderStatus[5].creationTime) : '') : "")}
                </Box>}
            </Box>

            {/* Port of Destination Status */}
            <Box className={`progress-step ${getPODStatus(order)}`}>
                
                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getPODStatus_color(order)}`, width: "2.36vw", height: "2.36vw", marginBottom: "0.5vw" }}>
                        <img src={getPODStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Destination'}
                </Box>
                {(order?.orderStatus[4]?.status === 'SUCCESS') && <Box className='prog-dec'>
                    {/*{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name} {order.orderStatus[6].status === 'SUCCESS' ? formatDateTime(order.orderStatus[6].updateTime) : (order?.orderStatus[6].status === 'IN_PROGRESS' ? (order.orderStatus[6].creationTime ? formatDateTime(order.orderStatus[6].creationTime) : '') : "")}*/}
                    {getPOD(order)}
                </Box>}
            </Box>

            {/* Custom Status */}
            <Box className={`progress-step ${getCustomsStatus(order)}`}>
                
                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getCustomsStatus_color(order)}`, width: "2.36vw", height: "2.36vw", marginBottom: "0.5vw" }}>
                        <img src={getCustomsStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" style={{ background: "#3c5293", width: "calc(100% - 3vw)", height: "1px" }}>
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Customs'}
                </Box>
                {<Box className='prog-dec'>
                    <p onClick={() => { openCustomsDialog(order) }} style={{ textDecoration: "underline", cursor: "pointer", margin:"0" }}>{order.orderStatus[7].status === 'SUCCESS' || order?.orderStatus[7].status === 'IN_PROGRESS' ? getCustomStatus(order) : ""}</p>
                </Box>}
            </Box>

            {/* Importer Status */}
            <Box className={`progress-step ${getImportStatus(order)}`}>
                
                <Grid item container style={{ width: "100%" }} alignItems="center" justifyContent="space-between">
                    <Grid item container justifyContent="center" alignItems="center" style={{ border: "1px solid #2fadee", borderRadius: "50%", background: `${getImportStatus_color(order)}`, width: "2.36vw", height: "2.36vw", marginBottom: "0.5vw" }}>
                        <img src={getImportStatus_image(order)} style={{ width: "1.3vw", height: "1.3vw" }} />
                    </Grid>
                </Grid>
                <Box className='progress-text tk-good-times'>
                    {'Delivered'}
                </Box>
                {<Box className='prog-dec'>
                    {order.orderStatus[8].status === 'SUCCESS' ? formatDateTime(order.orderStatus[8].updateTime) : (order?.orderStatus[8].status === 'IN_PROGRESS' ? (order.orderStatus[8].creationTime ? "ETA: " + formatDateTime(order.orderStatus[8].creationTime) : '') : "")}
                </Box>}
            </Box>
        </Grid>;
    };

    const getLastStatusNum = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return 6;
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return 5;
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return 4;
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return 3;
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return 2;
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return 2;
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return 1;
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return 1;
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return 1;
    }
    const getLastRoute = (order) => {
        if ("inprogress" === ((order.orderStatus[1].status === 'SUCCESS' || getLastStatusNum(order) > 1) ? 'done' : 'inprogress'))
            return 'Shipper'
        if ("inprogress" === ((order.orderStatus[3].status === 'SUCCESS' || getLastStatusNum(order) > 2) ? 'done' : (order.orderStatus[1].status === 'SUCCESS' ? 'inprogress' : '')))
            return 'Warehouse';
        if (getPOLStatus(order) === "inprogress")
            return "Departure";
        if (getPODStatus(order) === "inprogress")
            return "Destination";
        if (getCustomsStatus(order) === "inprogress")
            return "Customs";
        if (getImportStatus(order) === "inprogress")
            return "Delivered";

        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivered";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return "Customs";
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return "Destination";
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return "Departure";
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Shipper";
    }
    const getLastEvent1 = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivery to Consignee";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return getCustomStatus(order);
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return getPodStatus(order, true);
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return getPodStatus(order, false);
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Departure Waiting";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Trucking";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Need Confirmation";
    }
    const getLastEvent = (order) => {
        if (order.orderStatus[6]?.status != "SUCCESS") {
            var custDate = order.airStatus?.podDate ? order.airStatus?.podDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null)
            var date = order?.sinay?.arDate1 ? order.sinay.arDate1 : custDate;
            var curDate = new Date();
            var dt = new Date(date);
            var dt1 = dt.getTime() - curDate.getTime();
            return Number(Number(dt1 / (24000 * 3600)).toFixed(0)) >= 0 ? Number(dt1 / (24000 * 3600)).toFixed(0) + " days for destination" : "";
        }
        /*if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivery to Consignee";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return getCustomStatus(order);
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return getPodStatus(order, true);
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return getPodStatus(order, false);
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Departure Waiting";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "Trucking";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Need Confirmation";*/
    }

    const getPodStatus = (order, pod) => {
        var res = 0;
        if (order.shippingModeId == 1) {
            res = order.airStatus?.lStatus ? (pod ? "POD - " : "POL - ") + order.airStatus?.lStatus : "";
        } else {
            res = getSinayStatus(order.sinay?.status?.val?.metadata?.shippingStatus ? order.sinay?.status?.val?.metadata?.shippingStatus : "", order.sinay?.status ? order.sinay?.status : "", pod)
        }
        return res ? res : "";
    }
    const getCustomStatus = (order) => {
        if (order.shippingModeId == 1) {
            return order.airStatus?.lStatus ? "Customs - " + order.airStatus?.lStatus : "";
        } else {
            return getTaskyamStatus(order.taskyam?.data?.status)
        }
    }
    const getSinayStatus = (str, status, pod) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return (pod ? "POD - " : "POL - ") + msg.toLowerCase().replaceAll("_", " ");
        }
        return str ? (pod ? "POD - " : "POL - ") + str.toLowerCase().replaceAll("_", " ") : "";
    }
    const getTaskyamStatus = (id) => {
        switch (id) {
            case 0:
                return "";
            case 1:
                return "מצהר";
            case 2:
                return "זמינות";
            case 3:
                return "פקודת מסירה";
            case 4:
                return "בדיקת מכס";
            case 5:
                return "התרת מכס";
            case 6:
                return "התרת נמל";
            case 7:
                return "תעודת ליווי";
            case 8:
                return "בקשה להוצאה";
            case 9:
                return "משוב מהנמל";
            case 10:
                return "יציאת מטען";
        }
        return ""
    }
    const formatDateTime = (date) => {
        if (!date)
            return "-";
        var dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    const formatDate = (date) => {
        if (!date)
            return "-";
        var str = [];
        if (date.includes("T")) {
            str = date.split("T")[1].split(":");
            var time = str[0] + ":" + str[1];

            const dt = new Date(date);
            const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
            return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)} ${time}`;
        } else {
            return date;
        }
    }
    const getShowStatus = (order) => {
        return showImportsExport ? (showImports ? (order.locationId == 2 ? false : true) : (order.locationId != 2 ? false : true)) : true;
    }
    return (
        <TableContainer className="shipment-container" style={{width: "100vw", overflow: "hidden"}} >
            {(isLoading && showImports) && <Grid item container style={{ height: "300px" }} alignItems="center" justifyContent="center">
                <CircularProgress style={{ marginTop: "20px" }} />
            </Grid>}
            {showCustomsDialog &&
                <Dialog
                    open={showCustomsDialog}
                    onHandleCancel={closeCustomsDialog}
                    title='Customs'
                    maxWidth='sm'>
                    <Grid item container>
                        <Table>
                            <TableBody>
                                <TableRow style={{ background: "#eee" }}>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{formatDate(selectedOrder?.taskyam?.data?.CargoList?.ImportProcess?.ManifestDate)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{`מצהר`}</p>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{ background: "#fff" }}>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{formatDate(selectedOrder?.taskyam?.data?.CargoList?.ImportProcess?.PortUnloadingDate)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{`זמינות`}</p>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{ background: "#eee" }}>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{formatDate(selectedOrder?.taskyam?.data?.CargoList?.ImportProcess?.EscortCertificateDate)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{`תעודת ליווי`}</p>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{ background: "#fff" }}>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{formatDate(selectedOrder?.taskyam?.data?.CargoList?.ImportProcess?.CargoExitDate)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{`יציאת מטען`}</p>
                                    </TableCell>
                                </TableRow>
                                {(selectedOrder.sinay?.lclMap && selectedOrder.sinay?.lclMap?.selectedOrder >= 6) && <TableRow style={{ background: "#eee" }}>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{formatDate(selectedOrder?.sinay?.lclMap?.ExitDepoTime)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p style={{ margin: "10px" }}>{`יציאה מהמסוף`}</p>
                                    </TableCell>
                                </TableRow>}
                            </TableBody>
                        </Table>
                        <Grid item container>
                            <Button style={{ radius: "1px solid #0c65c1", color: "#0c65c1", margin: "0px 3px" }} className='btn-primary' onClick={() => closeCustomsDialog()}>
                                {'OK'}
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            }
            {!isMobile() ?
                <Table className='shipment-table' style={{ marginBottom: "2vw" }}>
                    {(orders?.length > 0 && !isLoading) &&
                        <TableBody>
                            {orders.map(order =>
                                <>{getShowStatus(order) && <TableRow key={order.id} className={`shipment-row ${isBookmark && "bookmark"}`} style={{ color: "#707070" }}>
                                    <TableCell valign='left' style={{ width: "10vw", paddingRight: "0" }}>

                                        <Grid className='shipment-no font-heebo' style={{}} alignItems="center">
                                            <img src={order.shippingModeId == 3 ? fclIcon : order.shippingModeId == 2 ? fclIcon : airIcon} alt="" style={{ paddingRight: "0.2vw", borderRight: "1px solid black" }} />

                                            {order.jobNumber && <Grid item container>
                                                <span style={{ color: "#00AEEF", overflow: "hidden", textWrap: "nowrap", fontSize: "1vw", marginTop: "0.2vw" }}>{order.shippingModeId == 3 ? "FCL" : order.shippingModeId == 2 ? "LCL" : "AIR"}</span>
                                            </Grid>}
                                        </Grid>
                                        {order.orderStage[4]?.departureStage[0]?.eta && <Grid className="font-heebo" item container justifyContent="flex-start" flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.9vw", fontWeight: "bold", color: "#707070", marginTop: "5px" }}>
                                            ETA: {getPOD(order)}
                                        </Grid>}
                                        {<Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'140px'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.8vw", fontWeight: "bold", color: "#707070" }}>
                                            {getLastEvent1(order)}
                                        </Grid>}
                                        {order.suppliersPO && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'7.36vw'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.7vw", color: "#959494", "-webkit-text-stroke": "thin", marginTop: "5px", marginBottom: "5px" }}>
                                            PO: <span onClick={() => orderDetailHandle(order, TABS[1])} style={{ overflow: "hidden", textWrap: "nowrap", textDecoration: "underline", cursor: "pointer" }} title={order.suppliersPO}>{order.suppliersPO}</span>
                                        </Grid>}
                                        {order.jobNumber && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'100%'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "0.6vw", color: "#707070", "-webkit-text-stroke": "thin", minWidth: "7vw" }}>
                                            <b style={{ textAlign: "left", width: "100%", overflow: "hidden", textWrap: "nowrap" }} title={order.jobNumber}>{order.jobNumber.substring(0,7)}</b>
                                        </Grid>}
                                    </TableCell>

                                    <TableCell style={{}}>
                                        {shipTransDetails(order)}
                                    </TableCell>

                                    <TableCell>
                                        {statusProgressBar(order)}
                                    </TableCell>

                                </TableRow>}</>
                            )}
                        </TableBody>}
                </Table>
                : <Grid items container>
                    {(orders?.length > 0 && !isLoading) &&
                        <Grid items container justifyContent="center" style={{ overflow: "hidden" }}>
                            {orders.map(order =>
                                <>{getShowStatus(order) && <TableRow key={order.id} className={`shipment-row-m `} style={{ color: "#707070", width: "100vw", padding: "5vw" }}>
                                    <TableCell valign='left' style={{ padding: "0", width: "40vw", overflow: "hidden" }}>
                                        <Grid className='shipment-no font-heebo' style={{}} alignItems="center">
                                            <img src={order.shippingModeId == 3 ? fclIcon : order.shippingModeId == 2 ? fclIcon : airIcon} alt="" style={{ paddingRight: "1vw", borderRight: "1px solid black" }} />

                                            {order.jobNumber && <Grid item container>
                                                <span style={{ color: "#00AEEF", overflow: "hidden", textWrap: "nowrap", fontSize: "4vw", marginTop: "0.2vw" , marginLeft: "2vw" }}>{order.shippingModeId == 3 ? "FCL" : order.shippingModeId == 2 ? "LCL" : "AIR"}</span>
                                            </Grid>}
                                        </Grid>
                                        {order.orderStage[4]?.departureStage[0]?.eta && <Grid className="font-heebo" item container justifyContent="flex-start" flexDirection="row" flexWrap="nowrap" style={{ fontSize: "3vw", fontWeight: "bold", color: "#707070", marginTop: "5px" }}>
                                            ETA: {getPOD(order)}
                                        </Grid>}
                                        {getLastEvent1(order) && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'140px'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "3vw", fontWeight: "bold", color: "#707070", marginTop: "5px" }}>
                                            {getLastEvent1(order)}
                                        </Grid>}
                                        {order.suppliersPO && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'38vw'} flexDirection="row" flexWrap="nowrap" style={{ overflow: "hidden", fontSize: "3vw", color: "#959494", "-webkit-text-stroke": "thin", marginTop: "5px", marginBottom: "5px" }}>
                                            PO: <span onClick={() => orderDetailHandle(order, TABS[1])} style={{ textWrap: "nowrap", textDecoration: "underline", cursor: "pointer" }} title={order.suppliersPO}>{order.suppliersPO}</span>
                                        </Grid>}
                                        {order.jobNumber && <Grid className="font-heebo" item container justifyContent="flex-start" maxWidth={'100%'} flexDirection="row" flexWrap="nowrap" style={{ fontSize: "2.5vw", color: "#707070", "-webkit-text-stroke": "thin", minWidth: "7vw" }}>
                                            <b style={{ textAlign: "left", width: "100%", overflow: "hidden", textWrap: "nowrap" }} title={order.jobNumber}>{order.jobNumber.substring(0, 7)}</b>
                                        </Grid>}
                                    </TableCell>

                                    <TableCell style={{ padding: "0", width: "50vw", overflow: "hidden" }}>
                                        <Box className='ship-trans-m' style={{ borderLeft:"1px solid #3B3A3A2E"}}>
                                            <Grid className="font-heebo" style={{ color: "#959494", "-webkit-text-stroke": "thin", width: "50vw" }}>
                                                {order.shipper.companyShortName && <Box className='pickup mr-5' style={{ fontSize: "3.3vw", color: "#707070", "-webkit-text-stroke": "thin", width: "50vw" }}>{order.shipper?.companyShortName ? order.shipper.companyShortName : "-"}</Box>}
                                                {(order.countryOfOriginId && order.poaId) && <Grid item container alignItems="center" justifyContent="center" className='pickup mr-5' style={{ fontSize: "3vw", width: "50vw" }}><span>{getCountryName(order)}</span> <span><img src={arrowLeft} style={{ width: "7vw", margin: "0 0.5vw" }} /></span> <span>{order.shippingModeId == 1 ? podAir.find(air => air.id == order.poaId)?.name : podSea.find(sea => sea.id == order.poaId)?.name}</span></Grid>}
                                                {/*{<Box className='pickup mr-5'>{getStatus(order)}</Box>}*/}
                                                {/*{<Box className='pickup mr-5'>{getLastEvent(order)}</Box>}*/}
                                                {<Grid item container className='shipment-action' alignItems="center" justifyContent="center" flexWrap="nowrap" flexDirection="row-reverse" style={{ width: "50vw" }}>
                                                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, TABS[4])} style={{ width: "12vw", color: `${chatRead(order) ? "#707070" : "white"}`, background: `${chatRead(order) ? "white" : "#00aeef"}` }} alignItems="center" flexWrap="nowrap">
                                                        <img src={chatRead(order) ? chatButton : chatButton1} /> <span>Chat</span>
                                                    </Grid>
                                                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, "Order Status")} style={{ width: "14vw" }} alignItems="center" flexWrap="nowrap">
                                                        <img src={detailButton} /> <span>Details</span>
                                                    </Grid>
                                                    <Grid className="actionButtons" item container onClick={() => orderDetailHandle(order, TABS[3])} style={{ width: "15vw" }} alignItems="center" flexWrap="nowrap">
                                                        <img src={invoiceButton} /> <span>Invoice</span>
                                                    </Grid>
                                                </Grid>}
                                            </Grid>
                                        </Box>
                                    </TableCell>
                                </TableRow>}</>
                            )}
                        </Grid>}
                </Grid>
            }
        </TableContainer>
    )
}

export default ShipmentDataRow;
