import { useCallback, useEffect, useState } from 'react';

import { Grid, Box, Button } from '@mui/material';
import { Dialog, MultilineField, TextField, SelectField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { onOrdersCountChange, getOrders, onChatCountChange, onChatCountTChange, onChatCountCTChange, onChatCountCChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import {
  onIdChange,
  onNotesChange,
  onUserNLIdChange,
  onUserNLNumberChange,
  resetConfirmationStage,
} from 'reducers/stage-reducers/confirmationStageSlicer';
import {addOrderFilter, addSearchFilter} from 'Utils';

const ConfirmationDialog = ({ order, openDialog, setOpenDialog, loginUser, pageName }) => {
  const dispatch = useDispatch();
  const onHandleChange = (event, action) => dispatch(action(event.target.value));
  const { userNLNames } = useSelector(state => state.predefinedData);
  const { userUSNames } = useSelector(state => state.predefinedData);
  const { userILNames } = useSelector(state => state.predefinedData);
  const { page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
  const { id, notes, userNLId, userNLNumber } = useSelector(state => state.confirmationStage);
  const [ilUsers, setIlUsers] = useState();
  const [nlUsers, setNlUsers] = useState();
  const [usUsers, setUSUsers] = useState();
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const currentStage = order.orderStage[0];

  const { 
    userLocalNumber,  
    userAbroadNumber,  
      consigneeId,
      customerId, 
    shipperId,
      countryOfOriginId, originState, fileNumber,
    shippingModeId,
    subModeId,
      userLocalId, userTracer, userCustoms, urgentShipment,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
      reminderDateAdv, ilReminderDateAdv, etaDate, etdDate, } = useSelector(state => state.orderTableFilter);

  const onHandleCloseDialog = () => {
    dispatch(resetConfirmationStage());
    setOpenDialog(false);
  };

  useEffect(() => {
    const orderConfirmationStage = currentStage.orderConfirmationStage[0];

    dispatch(onNotesChange(currentStage.notes));

    if (orderConfirmationStage?.id) {
      dispatch(onIdChange(orderConfirmationStage.id));
      dispatch(onUserNLIdChange(orderConfirmationStage.userNLId));
      dispatch(onUserNLNumberChange(orderConfirmationStage.userNLNumber));
    }
  }, [dispatch]);

  
  useEffect(() => {
    // predefined user abroad data get
    if(!nlUsers || nlUsers==undefined){
      sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
        successCallback: response => {setNlUsers(response);},
        failedCallback,
      });
    }
  }, [nlUsers, failedCallback, setNlUsers]);

  useEffect(() => {
    // predefined user abroad data get
    if(!usUsers || usUsers==undefined){
      sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
        successCallback: response => {setUSUsers(response);},
        failedCallback,
      });
    }
  }, [usUsers, failedCallback, setUSUsers]);

  useEffect(() => {
    // predefined user local data get
    if(!ilUsers || ilUsers==undefined){
      sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
        successCallback: response => {setIlUsers(response);},
        failedCallback,
      });
    }
  }, [ilUsers, failedCallback, setIlUsers]);

  const onStageHandleSave = id => {
  if(userNLNumber === "" || userNLNumber == null){
    dispatch(snackbarToggle({ type: 'error', message: 'NL # is empty!' }));
    return;
  }
    const orderConfirmationStage = [
      {
        userNLId:
          userNLId ||
          ((loginUser.location.code === 'US' || selectedLocationIndex==1) ? userUSNames.find(user => user.name === loginUser.fullName)?.id : ((loginUser.location.code === 'NL' || selectedLocationIndex==0) ? userNLNames.find(user => user.name === loginUser.fullName)?.id : null)),
        userNLNumber,
      },
    ];

    if (id) {
      orderConfirmationStage[0].id = id;
    }

    const newOrder = {
      ...order,
      orderStage: [
        {
          ...currentStage,
          notes,
          orderConfirmationStage,
        },
        order.orderStage[1],
        order.orderStage[2],
        order.orderStage[3],
        order.orderStage[4],
      ],
    };

    if (userNLNumber != order?.orderStage[0]?.orderConfirmationStage[0]?.userNLNumber) {
      var userIL = order.userILId;
      var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
      var lengthIL = ilUsers.length;
      var lengthNL = nlUsers.length;
      var exists = false;
      var chat = {
        'time': '',
        'text': 'Order has been confirmed',
        'initiator': '',
        'initiatorType': '',
        'location':'',
        'ILStatus': false,
        'NLStatus':false
      }
      for(var i = 0 ; i < lengthIL ; i++){
        if( ilUsers[i].fullName == loginUser.fullName){
          var date = new Date(); 
          chat['time'] = date.toUTCString();
          chat['initiator'] = loginUser.fullName;
          chat['initiatorType'] = 1;
          chat['location'] = 'IL';
          if(userIL == ilUsers[i].id){
            chat['ILStatus'] = true;
            chat['initiatorType'] = 2;
          }
          else if(ilUsers[i].roleId == 1)
            chat['ILStatus'] = false;
          exists = true;
          break;
        }
      }
      if(!exists){
        for(var i = 0 ; i < lengthNL ; i++){
          if( nlUsers[i].fullName == loginUser.fullName){
            var date = new Date(); 
            chat['time'] = date.toUTCString();
            chat['initiator'] = loginUser.fullName;
            chat['initiatorType'] = 1;
            chat['location'] = 'NL';
            if(userNL == nlUsers[i].id){
              chat['NLStatus'] = true;
              chat['initiatorType'] = 3;
            }
            else if(nlUsers[i].roleId == 1)
              chat['NLStatus'] = false;
            exists = true;
            break;
          }
        }

      for(var i = 0 ; i < usUsers.length ; i++){
          if( usUsers[i].fullName == loginUser.fullName){
            var date = new Date(); 
            chat['time'] = date.toUTCString();
            chat['initiator'] = loginUser.fullName;
            chat['initiatorType'] = 1;
            chat['location'] = 'US';
            if(userNL == usUsers[i].id){
              chat['NLStatus'] = true;
              chat['initiatorType'] = 3;
            }
            else if(usUsers[i].roleId == 1)
              chat['NLStatus'] = false;
            exists = true;
            break;
          }
        }
      }
      if(!order.chat){
        var chat_list = [];
        chat_list[0] = chat;
        //newOrder.chat = JSON.stringify(chat_list);
      }
      else{
        var chat_list = JSON.parse(order.chat);
        chat_list.push(chat);
        //newOrder.chat = JSON.stringify(chat_list);
      }
    }

    sendRequest(`/api/order?fromStage=1&loginUserId=`+loginUser?.id+`&actionOn=ConfirmationDialog`, REQUEST_ACTIONS.PUT, newOrder, {
        successCallback: () => {
            sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));

                    if (userNLNumber != order?.orderStage[0]?.orderConfirmationStage[0]?.userNLNumber) {
                        //newOrder.chat = addStatusChatMessage(order, 'WH', 'Arrived', ilUsers, nlUsers, usUsers, loginUser);
                        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
                        onUpdateChat(order.id, chatString, 1)
                    } else {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseDialog();
                    }
                },
                failedCallback,
            });
           // dispatch(snackbarToggle({ type: 'success', message: 'Stage saved successfully' }));
            

        /**/
            sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                    dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                    dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount));sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));},
        failedCallback,
      });
      },
      failedCallback,
    });
  };


    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=` + loginUser?.id + `&actionOn=ConfirmationDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                //dispatch(snackbarToggle({ type: 'success', message: 'chat updated successfully' }));
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        onHandleCloseDialog();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };
  return (
    <Dialog maxWidth='md' open={openDialog} onHandleCancel={onHandleCloseDialog} title={'ORDER CONFIRMATION - '+order?.jobNumber}>
      <Box component='form' noValidate autoComplete='off'>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <SelectField
              lg={null}
                          label={(loginUser.location.code === 'US' || selectedLocationIndex == 1) ? 'User US' : (selectedLocationIndex == 2 ? 'User IL': 'User NL') }
              value={
                userNLId ||
                ((loginUser.location.code === 'US' || selectedLocationIndex==1)
                  ? userUSNames.find(user => user.name === loginUser.fullName)?.id
                  : ((loginUser.location.code === 'NL' || selectedLocationIndex==0)
                  ? userNLNames.find(user => user.name === loginUser.fullName)?.id
                  : null))
              }
              onChange={event => onHandleChange(event, onUserNLIdChange)}
                          options={(loginUser.location.code === 'US' || selectedLocationIndex == 1) ? userUSNames : (selectedLocationIndex == 2 ? userILNames : userNLNames)}
            />
            <TextField
              lg={null}
              type='text'
                          label={(loginUser.location.code === 'US' || selectedLocationIndex == 1) ? 'US #' : (selectedLocationIndex == 2 ? 'IL #' : 'NL #')}
              value={userNLNumber}
              onChange={event => onHandleChange(event, onUserNLNumberChange)}
            />
            <MultilineField
              xs={12}
              sm={null}
              md={null}
              lg={null}
              label='Notes'
              value={notes}
              onChange={event => onHandleChange(event, onNotesChange)}
            />
          </Grid>
          <Grid container item xs={12} justifyContent='flex-end'>
            <Button
              variant='contained'
              sx={{ mr: 2 }}
              onClick={id ? () => onStageHandleSave(id) : () => onStageHandleSave(null)}>
              Save
            </Button>
            <Button variant='contained' color='secondary' onClick={onHandleCloseDialog}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
