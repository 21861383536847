import { useCallback, useEffect, useState } from 'react';

import { Grid, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders, onOrdersCountChange, onChatCountChange, onChatCountCChange, onChatCountTChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onPageChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import {
    //  onUserLocalNumberChange,
    onUserAbroadNumberChange,
    onSearchChange,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addOrderFilter, addSearchFilter, usePrevious } from 'Utils';

const OrderSearch = ({ loginUser, setSearchSpinner, pageName, searchText, setSearchText }) => {
    const dispatch = useDispatch();
    const [varTimeout, setVarTimeout] = useState();
    const [sear, setSear] = useState("");
    const [first, setFirst] = useState(true);
    //const [searchText, setSearchText] = useState();

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms,
        urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate, fileNumber,
        selectedLocationIndex,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, etaDate, etdDate, } = useSelector(state => state.orderTableFilter);
    const { page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const onHandleChange = (value, action) => {
        //
        dispatch(action(value))
    };
    useEffect(() => {
        setSearchText(search)
    }, [search]);

    useEffect(() => {
        if (varTimeout) {
            clearTimeout(varTimeout);
        }
        if (first) {
            setFirst(false)
        } else {
            if (!searchText || searchText?.length > 0) {
                setSear(searchText)
                setVarTimeout(setTimeout(() => {
                    sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, searchText, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            dispatch(onOrdersCountChange(response?.ordersCount));
                            sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                            dispatch(onChatCountChange(response?.unreadChatCount));
                            dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                            dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                            dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                            sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                            dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                            dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                        },
                        failedCallback,
                    });

                    setSearchSpinner(true);
                    // filtered orders get
                    sendRequest(
                        `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, searchText, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)}page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, searchText, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)?.length ? 0 : page}&size=${size}`,
                        REQUEST_ACTIONS.GET,
                        '',
                        {
                            successCallback: response => {
                                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                setSearchSpinner(false);
                                if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, searchText, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName, fileNumber, etaDate, etdDate)) {
                                    dispatch(onPageChange(0));
                                }
                            },
                            failedCallback,
                        },
                    );
                }, varTimeout ? 700 : 0));
            }
        }
    }, [userLocalNumber, userAbroadNumber, page, size, setSearchSpinner, search, searchText, dispatch, failedCallback, consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId, userTracer, userCustoms,
        urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, fileNumber, etaDate, etdDate]);
    function trimStr(str) {
        if (!str) return str;
        return str.replace(/^\s+|\s+$/g, '');
    }
    return (
        <Grid container item justifyContent='flex-end' className="header">

            <TextField
                xs={12}
                sm={null}
                md={null}
                lg={null}
                label=''
                type='search'
                className="search"
                value={sear}
                endAdornment={
                    <InputAdornment position='end'>
                        <SearchIcon onClick={() => {
                        setSearchText(sear?.trim())
                        onHandleChange(sear?.trim(), onSearchChange)
                        }} style={{ cursor: "pointer" }} />
                    </InputAdornment>
                }
                onChange={event => setSear(event.target.value)}
                onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                        // Do code here
                        setSearchText(ev.target.value?.trim())
                        onHandleChange(ev.target.value?.trim(), onSearchChange)
                        ev.preventDefault();
                    }
                }}
            />
        </Grid>
    );
};

//      <TextField
//        xs={2}
//        sm={null}
//        md={null}
//        lg={null}
//        type='number'
//        label='IL job #'
//        value={userLocalNumber}
//        onChange={event => onHandleChange(event, onUserLocalNumberChange)}
//      />
//      <TextField
//        xs={2}
//        sm={null}
//        md={null}
//        lg={null}
//        type='number'
//        label='NL job #'
//        value={userAbroadNumber}
//        onChange={event => onHandleChange(event, onUserAbroadNumberChange)}
//      />

export default OrderSearch;
